import {handleResponse} from "@/utils/handle-response";
import {requestOptions} from "@/utils/request-options";


export const authenticationService = {
    login,
    logout,
};

function login(email, password) {
    return fetch(`${process.env.VUE_APP_BASE_URL}/api/login`, requestOptions.post({ "email": email, "password": password }))
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);

            return data;
        });
}

function logout() {
    // remove user from local storage to log user out
}
