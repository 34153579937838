// stores/cart.js
import { defineStore } from 'pinia';
import generateUniqueId from "@/utils/generate-id";

export const useCartStore = defineStore('carts', {
  state: () => ({
    carts: [],
    activeCartId: null,
  }),
  getters: {
      cartCount: (state) => state.cart.length
  },
  actions: {
    createCart() {
      const newCart = {
        transactionId: generateUniqueId(8).toUpperCase(), // Generate a unique ID
        items: [],
      };
      this.carts.push(newCart);
      this.activeCartId = newCart.transactionId; // Set the newly created cart as active
    },

    addItem(transactionId, product) {
      const cart = this.carts.find(cart => cart.transactionId === transactionId);
      if (cart) {
        const existingItem = cart.items.find(item => item.productId === product.productId);
        if (!existingItem) {
         cart.items.push({...product, quantityInCart: 1});
        }
      }
    },
    removeItem(transactionId, productId) {
      const cart = this.carts.find(cart => cart.transactionId === transactionId);
        if(cart) {
            cart.items = cart.items.filter(item => item.productId !== productId)
        }
    },
    removeCart(transactionId) {
      this.carts = this.carts.filter(cart => cart.transactionId !== transactionId);
      //Important: Check if the removed cart was the active one
      if (this.activeCartId === transactionId) {
        this.activeCartId = this.carts.length > 0 ? this.carts[0].transactionId : null; // Set a new active cart or null
      }
    },
    incrementItemQuantity(transactionId, productId) {
      const cart = this.carts.find(cart => cart.transactionId === transactionId);
      if (cart) {
        const existingItem = cart.items.find(item => item.productId === productId);
        if (existingItem) {
         existingItem.quantityInCart++;
        }
      }
    },
    decrementItemQuantity(transactionId, productId) {
      const cart = this.carts.find(cart => cart.transactionId === transactionId);
      if (cart) {
        const item = cart.items.find(item => item.productId === productId);
        if (item && item.quantityInCart > 1) {
          item.quantityInCart--;
        } else if (item && item.quantityInCart === 1) {
          this.removeItem(transactionId, productId);
        }
      }
    },
    setActiveCart(transactionId) {
        this.activeCartId = transactionId
    },
    clearCart() {
      this.carts = []; // Simply set the cart array to an empty array
    },
    setCart(carts, activeCartId) {
        this.carts = carts
      this.activeCartId = activeCartId
    }
  },
});


