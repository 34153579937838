import {handleResponse} from "@/utils/handle-response";
import {requestOptions} from "@/utils/request-options";


export const ordersService = {
   placeOrder,
  placeBatchOrder
};

function placeOrder(body) {
    return fetch(`${process.env.VUE_APP_BASE_URL}/api/public/users/place-order`, requestOptions.post(body))
        .then(handleResponse)
        .then(data => {
            return data;
        });
}
function placeBatchOrder(body) {
    return fetch(`${process.env.VUE_APP_BASE_URL}/api/public/users/batch/orders`, requestOptions.post(body))
        .then(handleResponse)
        .then(data => {
            return data;
        });
}
