<template>
  <!-- Payment Completed -->
  <div id="payment-completed" aria-labelledby="payment-completed" class="modal fade modal-default">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body text-center">
          <form @submit.prevent="submitForm">
            <div class="icon-head">
              <a href="javascript:;">
                <vue-feather class="feather-40" type="check-circle"></vue-feather>
              </a>
            </div>
            <h4>Payment Completed</h4>
            <p class="mb-0">Do you want to Print Receipt for the Completed Order</p>
            <div class="modal-footer d-sm-flex justify-content-between">
              <button class="btn btn-primary flex-fill" data-bs-target="#print-receipt" data-bs-toggle="modal"
                      type="button">Print Receipt<i
                  class="feather-arrow-right-circle icon-me-5"></i></button>
              <button class="btn btn-secondary flex-fill" type="submit">Next Order<i
                  class="feather-arrow-right-circle icon-me-5"></i></button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Payment Completed -->

  <!-- Print Receipt -->
  <div id="print-receipt" aria-labelledby="print-receipt" class="modal fade modal-default">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="d-flex justify-content-end">
          <button aria-label="Close" class="close p-0" data-bs-dismiss="modal" type="button">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="icon-head text-center">
            <a href="javascript:;">
              <img alt="Receipt Logo" height="30" src="@/assets/img/logo.png" width="100">
            </a>
          </div>
          <div class="text-center info text-center">
            <h6>Dreamguys Technologies Pvt Ltd.,</h6>
            <p class="mb-0">Phone Number: +1 5656665656</p>
            <p class="mb-0">Email: <a href="mailto:example@gmail.com">example@gmail.com</a></p>
          </div>
          <div class="tax-invoice">
            <h6 class="text-center">Tax Invoice</h6>
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <div class="invoice-user-name"><span>Name: </span><span>John Doe</span></div>
                <div class="invoice-user-name"><span>Invoice No: </span><span>CS132453</span></div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="invoice-user-name"><span>Customer Id: </span><span>#LL93784</span></div>
                <div class="invoice-user-name"><span>Date: </span><span>01.07.2022</span></div>
              </div>
            </div>
          </div>
          <table class="table-borderless w-100 table-fit">
            <thead>
            <tr>
              <th># Item</th>
              <th>Price</th>
              <th>Qty</th>
              <th class="text-end">Total</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in items" :key="item.productId">
              <td>{{ item.productId }}. {{ item.productName }}</td>
              <td>${{ item.price }}</td>
              <td>{{ item.quantityInCart }}</td>
              <td class="text-end">${{ item.quantityInCart * item.price }}</td>
            </tr>
            <tr>
              <td colspan="4">
                <table class="table-borderless w-100 table-fit">
                  <tr>
                    <td>Sub Total :</td>
                    <td class="text-end">${{ subtotal.toFixed(2) }}</td>
                  </tr>
                  <tr>
                    <td>Discount :</td>
                    <td class="text-end">-${{ discount.toFixed(2) }}</td>
                  </tr>
                  <tr>
                    <td>Shipping :</td>
                    <td class="text-end">0.00</td>
                  </tr>
                  <tr>
                    <td>Tax (5%) :</td>
                    <td class="text-end">${{ tax.toFixed(2) }}</td>
                  </tr>
                  <tr>
                    <td>Due :</td>
                    <td class="text-end">$0.00</td>
                  </tr>
                  <tr>
                    <td>Total Payable :</td>
                    <td class="text-end">${{ bill.toFixed(2) }}</td>
                  </tr>
                </table>
              </td>
            </tr>
            </tbody>
          </table>
          <div class="text-center invoice-bar">
            <p>**VAT against this challan is payable through central registration. Thank you for your business!
            </p>
<!--            <a href="javascript:;">-->
<!--              <img alt="Barcode" src="@/assets/img/barcode/barcode-03.jpg">-->
<!--            </a>-->
<!--            <p>Sale 31</p>-->
            <p>Thank You For Shopping With Us. Please Come Again</p>
            <a class="btn btn-primary" href="javascript:void(0);">Print Receipt</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Print Receipt -->

  <!-- Products -->
  <div id="products" aria-labelledby="products" class="modal fade modal-default pos-modal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header p-4 d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <h5 class="me-4">Products</h5>
            <span class="badge bg-info d-inline-block mb-0">Order ID : #666614</span>
          </div>
          <button aria-label="Close" class="close" data-bs-dismiss="modal" type="button">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body p-4">
          <form @submit.prevent="submitForm">
            <div class="product-wrap">
              <div class="product-list d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center flex-fill">
                  <a class="img-bg me-2" href="javascript:void(0);">
                    <img alt="Products" src="@/assets/img/products/pos-product-16.png">
                  </a>
                  <div class="info d-flex align-items-center justify-content-between flex-fill">
                    <div>
                      <span>PT0005</span>
                      <h6><a href="javascript:void(0);">Red Nike Laser</a></h6>
                    </div>
                    <p>$2000</p>
                  </div>
                </div>

              </div>
              <div class="product-list d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center flex-fill">
                  <a class="img-bg me-2" href="javascript:void(0);">
                    <img alt="Products" src="@/assets/img/products/pos-product-17.png">
                  </a>
                  <div class="info d-flex align-items-center justify-content-between flex-fill">
                    <div>
                      <span>PT0235</span>
                      <h6><a href="javascript:void(0);">Iphone 14</a></h6>
                    </div>
                    <p>$3000</p>
                  </div>
                </div>
              </div>
              <div class="product-list d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center flex-fill">
                  <a class="img-bg me-2" href="javascript:void(0);">
                    <img alt="Products" src="@/assets/img/products/pos-product-16.png">
                  </a>
                  <div class="info d-flex align-items-center justify-content-between flex-fill">
                    <div>
                      <span>PT0005</span>
                      <h6><a href="javascript:void(0);">Red Nike Laser</a></h6>
                    </div>
                    <p>$2000</p>
                  </div>
                </div>
              </div>
              <div class="product-list d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center flex-fill">
                  <a class="img-bg me-2" href="javascript:void(0);">
                    <img alt="Products" src="@/assets/img/products/pos-product-17.png">
                  </a>
                  <div class="info d-flex align-items-center justify-content-between flex-fill">
                    <div>
                      <span>PT0005</span>
                      <h6><a href="javascript:void(0);">Red Nike Laser</a></h6>
                    </div>
                    <p>$2000</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer d-sm-flex justify-content-end">
              <button class="btn btn-secondary" data-bs-dismiss="modal" type="button">Cancel</button>
              <button class="btn btn-primary" type="submit">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Products -->

  <div id="create" aria-hidden="true" aria-labelledby="create" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Create</h5>
          <button aria-label="Close" class="close" data-bs-dismiss="modal" type="button">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <div class="row">
              <div class="col-lg-6 col-sm-12 col-12">
                <div class="input-blocks">
                  <label>Customer Name</label>
                  <input class="form-control" type="text">
                </div>
              </div>
              <div class="col-lg-6 col-sm-12 col-12">
                <div class="input-blocks">
                  <label>Email</label>
                  <input class="form-control" type="email">
                </div>
              </div>
              <div class="col-lg-6 col-sm-12 col-12">
                <div class="input-blocks">
                  <label>Phone</label>
                  <input class="form-control" type="text">
                </div>
              </div>
              <div class="col-lg-6 col-sm-12 col-12">
                <div class="input-blocks">
                  <label>Country</label>
                  <input class="form-control" type="text">
                </div>
              </div>
              <div class="col-lg-6 col-sm-12 col-12">
                <div class="input-blocks">
                  <label>City</label>
                  <input type="text">
                </div>
              </div>
              <div class="col-lg-6 col-sm-12 col-12">
                <div class="input-blocks">
                  <label>Address</label>
                  <input type="text">
                </div>
              </div>
            </div>
            <div class="modal-footer d-sm-flex justify-content-end">
              <button class="btn btn-cancel" data-bs-dismiss="modal" type="button">Cancel</button>
              <button class="btn btn-submit me-2" type="submit">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- Hold -->
  <div id="hold-order" aria-labelledby="hold-order" class="modal fade modal-default pos-modal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header p-4">
          <h5>Hold order</h5>
          <button aria-label="Close" class="close" data-bs-dismiss="modal" type="button">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body p-4">
          <form @submit.prevent="submitForm">
            <h2 class="text-center p-4">${{ total }}</h2>
            <div class="input-block">
              <label>Order Reference</label>
              <input class="form-control" placeholder="" type="text" value="">
            </div>
            <p>The current order will be set on hold. You can retreive this order from the pending order button.
              Providing a reference to it might help you to identify the order more quickly.</p>
            <div class="modal-footer d-sm-flex justify-content-end">
              <button class="btn btn-secondary" data-bs-dismiss="modal" type="button">Cancel</button>
              <button class="btn btn-primary" data-bs-dismiss="modal" type="submit">Confirm</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Hold -->
</template>

<script>
export default {
  props: {
    total: String,
    onHold: { // Define the prop
      type: Function,
      required: true, // Make the prop required
    },
    items: {
      type: Array,
      required: true,
    },
    tax: {
      type: Number,
      default: null, // Makes it optional
    },
    subtotal: {
      type: Number,
      default: null, // Makes it optional
    },
    discount: {
      type: Number,
      default: null, // Makes it optional
    },
    bill: {
      type: Number,
      default: null, // Makes it optional
    },
  },
  data() {
    return {
      Tax: ["Exclusive", "Inclusive"],
      Discount: ["Percentage", "Early payment discounts"],
      Sale: ["Kilogram", "Grams"]
    }
  },
  methods: {
    submitForm() {
      this.onHold();
      this.$router.push("/sales/pos");
    },
  },
}
</script>
