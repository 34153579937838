import {handleResponse} from "@/utils/handle-response";
import {requestOptions} from "@/utils/request-options";


export const categoryService = {
    getAllCategory,
    getCategoryById,
    addCategory
};

function getAllCategory(pageNumber, pageSize) {
    return fetch(`${process.env.VUE_APP_BASE_URL}/api/public/categories?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=categoryName&sortOrder=asc`, requestOptions.get())
        .then(handleResponse)
}

function getCategoryById(sku) {
   return fetch(`${process.env.VUE_APP_BASE_URL}/api/public/products/sku/${sku}`, requestOptions.get())
        .then(handleResponse)
}

function addCategory(categoryName, categorySlug, status) {
    return fetch(`${process.env.VUE_APP_BASE_URL}/api/admin/category`, requestOptions.post(
      {
            "categoryId": 0,
            "categoryName": categoryName,
            "categorySlug": categorySlug,
            "categoryStatus": status ? "ACTIVE" : "DEACTIVATED"
          }
    ))
        .then(handleResponse)
        .then(data => {
            return data;
        });
}
