<template>
  <!-- Header -->
  <div class="header">

    <!-- Logo -->
    <div class="header-left active">
      <router-link class="logo logo-normal" to="/sales/pos">
        <img alt="" src="@/assets/img/logo.png">
      </router-link>
      <router-link class="logo logo-white" to="/sales/pos">
        <img alt="" src="@/assets/img/logo-white.png">
      </router-link>
      <router-link class="logo-small" to="/sales/pos">
        <img alt="" src="@/assets/img/logo-small.png">
      </router-link>
      <a id="toggle_btn" href="javascript:void(0);" @click="toggleSidebar">
        <vue-feather type="chevrons-left"></vue-feather>
      </a>
    </div>
    <!-- /Logo -->

    <a id="mobile_btn" class="mobile_btn" href="javascript:void(0);" @click="toggleSidebar1">
            <span class="bar-icon">
                <span></span>
                <span></span>
                <span></span>
            </span>
    </a>

    <!-- Header Menu -->
    <ul class="nav user-menu">

      <!-- Search -->
      <li class="nav-item nav-searchinputs">
        <div class="top-nav-search">
          <a class="responsive-search" href="javascript:void(0);">
            <i class="fa fa-search"></i>
          </a>
          <form action="javascript:void(0);" class="dropdown">
            <div id="dropdownMenuClickable" class="searchinputs dropdown-toggle" data-bs-auto-close="false"
                 data-bs-toggle="dropdown">
              <input placeholder="Search" type="text">
              <div class="search-addon">
                <span><vue-feather class="feather-14" type="x-circle"></vue-feather></span>
              </div>
            </div>
            <div aria-labelledby="dropdownMenuClickable" class="dropdown-menu search-dropdown">
              <div class="search-info">
                <h6><span><vue-feather class="feather-16" type="search"></vue-feather></span>Recent Searches
                </h6>
                <ul class="search-tags">
                  <li><a href="javascript:void(0);">Products</a></li>
                  <li><a href="javascript:void(0);">Sales</a></li>
                  <li><a href="javascript:void(0);">Applications</a></li>
                </ul>
              </div>
              <div class="search-info">
                <h6><span><vue-feather class="feather-16" type="help-circle"></vue-feather></span>Help</h6>
                <p>How to Change Product Volume from 0 to 200 on Inventory management</p>
                <p>Change Product Name</p>
              </div>
              <div class="search-info">
                <h6><span><vue-feather class="feather-16" type="user"></vue-feather></span>Customers</h6>
                <ul class="customers">
                  <li>
                    <a href="javascript:void(0);">Aron Varu<img alt=""
                                                                class="img-fluid" src="@/assets/img/profiles/avator1.jpg"></a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">Jonita<img alt=""
                                                             class="img-fluid" src="@/assets/img/profiles/avatar-01.jpg"></a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">Aaron<img alt=""
                                                            class="img-fluid" src="@/assets/img/profiles/avatar-10.jpg"></a>
                  </li>
                </ul>
              </div>
            </div>
          </form>
        </div>
      </li>
      <!-- /Search -->


      <!-- Select Store -->
      <li class="nav-item dropdown has-arrow main-drop select-store-dropdown">
        <a class="dropdown-toggle nav-link select-store" data-bs-toggle="dropdown" href="javascript:void(0);">
                    <span class="user-info">
                        <span class="user-letter">
                            <img alt="Store Logo" class="img-fluid" src="@/assets/img/store/store-01.png">
                        </span>
                        <span class="user-detail">
<!--                            <span class="user-name">Select Store</span>-->
                          <span class="user-name">Principal Store</span>
                        </span>
                    </span>
        </a>
        <div class="dropdown-menu dropdown-menu-right">
          <a class="dropdown-item" href="javascript:void(0);">
            <img alt="Store Logo" class="img-fluid" src="@/assets/img/store/store-01.png"> Grocery Alpha
          </a>
          <a class="dropdown-item" href="javascript:void(0);">
            <img alt="Store Logo" class="img-fluid" src="@/assets/img/store/store-02.png"> Grocery Apex
          </a>
          <a class="dropdown-item" href="javascript:void(0);">
            <img alt="Store Logo" class="img-fluid" src="@/assets/img/store/store-03.png"> Grocery Bevy
          </a>
          <a class="dropdown-item" href="javascript:void(0);">
            <img alt="Store Logo" class="img-fluid" src="@/assets/img/store/store-04.png"> Grocery Eden
          </a>
        </div>
      </li>
      <!-- /Select Store -->

      <!-- Flag -->
      <li class="nav-item dropdown has-arrow flag-nav nav-item-box">
        <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="javascript:void(0);" role="button">
          <img alt="Language" class="img-fluid" src="@/assets/img/flags/us.png">
        </a>
        <div class="dropdown-menu dropdown-menu-right">
          <a class="dropdown-item active" href="javascript:void(0);">
            <img alt="" height="16" src="@/assets/img/flags/us.png"> English
          </a>
          <a class="dropdown-item" href="javascript:void(0);">
            <img alt="" height="16" src="@/assets/img/flags/fr.png"> French
          </a>
          <a class="dropdown-item" href="javascript:void(0);">
            <img alt="" height="16" src="@/assets/img/flags/es.png"> Spanish
          </a>
          <a class="dropdown-item" href="javascript:void(0);">
            <img alt="" height="16" src="@/assets/img/flags/de.png"> German
          </a>
        </div>
      </li>
      <!-- /Flag -->

      <li class="nav-item nav-item-box">
        <a id="btnFullscreen" href="javascript:void(0);" @click="initFullScreen">
          <vue-feather type="maximize"></vue-feather>
        </a>
      </li>
      <li class="nav-item nav-item-box">
        <router-link to="/sales/sales-list">
          <vue-feather type="shopping-cart"></vue-feather>
          <span class="badge rounded-pill">3</span>
        </router-link>
      </li>
      <li class="nav-item nav-item-box">
        <router-link to="#" @click="$emit('open')" :disabled="count === 0" :class="`${count === 0 ? 'cursor-not-allowed' : ''}`">
          <vue-feather type="shopping-bag"></vue-feather>
          <span v-if="count > 0" class="badge rounded-pill">{{count}}</span>
        </router-link>
      </li>

      <li class="nav-item dropdown has-arrow main-drop">
        <a class="dropdown-toggle nav-link userset" data-bs-toggle="dropdown" href="javascript:void(0);">
                    <span class="user-info">
                        <span class="user-letter">
                            <img alt="" class="img-fluid" src="@/assets/img/avatar/avatar-18.jpg">
                        </span>
                        <span class="user-detail">
                            <span class="user-name">Belinda</span>
                            <span class="user-role">Cashier #POS001</span>
                        </span>
                    </span>
        </a>
        <div class="dropdown-menu menu-drop-user">
          <div class="profilename">
            <div class="profileset">
                            <span class="user-img"><img alt="" src="@/assets/img/avatar/avatar-18.jpg">
                                <span class="status online"></span></span>
              <div class="profilesets">
                <h6>Belinda</h6>
                <h5>Cashier #POS001</h5>
              </div>
            </div>
            <hr class="m-0">
            <router-link class="dropdown-item" to="/pages/profile">
              <vue-feather class="me-2"
                           type="user"></vue-feather>
              My
              Profile
            </router-link>
            <router-link class="dropdown-item" to="/settings/general-settings">
              <vue-feather class="me-2"
                           type="settings"></vue-feather>
              Settings
            </router-link>
            <hr class="m-0">
            <router-link class="dropdown-item logout pb-0" to="/"><img alt="img"
                                                                       class="me-2" src="@/assets/img/icons/log-out.svg">Logout
            </router-link>
          </div>
        </div>
      </li>
    </ul>
    <!-- /Header Menu -->

    <!-- Mobile Menu -->
    <div class="dropdown mobile-user-menu">
      <a aria-expanded="false" class="nav-link dropdown-toggle" data-bs-toggle="dropdown"
         href="javascript:void(0);"><i class="fa fa-ellipsis-v"></i></a>
      <div class="dropdown-menu dropdown-menu-right">
        <router-link class="dropdown-item" to="/pages/profile">My Profile</router-link>
        <router-link class="dropdown-item" to="/settings/general-settings">Settings</router-link>
        <router-link class="dropdown-item" to="/">Logout</router-link>
      </div>
    </div>
    <!-- /Mobile Menu -->
  </div>
  <!-- /Header -->

  <side-settings></side-settings>
</template>

<script>
export default {
  name: "pos-menu",
  emits: ['open'],
  props: {
    count: {
      type: Number,
      default: null, // Makes it optional
    },
  },
  data() {
    return {};
  },
  mounted() {
    this.initMouseoverListener();
  },
  methods: {
    toggleSidebar1() {
      const body = document.body;
      body.classList.toggle("slide-nav");
    },
    toggleSidebar() {
      const body = document.body;
      body.classList.toggle("mini-sidebar");
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
          !document.fullscreenElement &&
          /* alternative standard method */
          !document.mozFullScreenElement &&
          !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },

    initMouseoverListener() {
      document.addEventListener('mouseover', this.handleMouseover);
    },
    handleMouseover(e) {
      e.stopPropagation();

      const body = document.body;
      const toggleBtn = document.getElementById('toggle_btn');

      if (body.classList.contains('mini-sidebar') && this.isElementVisible(toggleBtn)) {
        const target = e.target.closest('.sidebar, .header-left');

        if (target) {
          body.classList.add('expand-menu');
          this.slideDownSubmenu();
        } else {
          body.classList.remove('expand-menu');
          this.slideUpSubmenu();
        }

        e.preventDefault();
      }
    },
    isElementVisible(element) {
      return element.offsetWidth > 0 || element.offsetHeight > 0;
    },
    slideDownSubmenu() {
      const subdropPlusUl = document.getElementsByClassName('subdrop');
      for (let i = 0; i < subdropPlusUl.length; i++) {
        const submenu = subdropPlusUl[i].nextElementSibling;
        if (submenu && submenu.tagName.toLowerCase() === 'ul') {
          submenu.style.display = 'block';
        }
      }
    },
    slideUpSubmenu() {
      const subdropPlusUl = document.getElementsByClassName('subdrop');
      for (let i = 0; i < subdropPlusUl.length; i++) {
        const submenu = subdropPlusUl[i].nextElementSibling;
        if (submenu && submenu.tagName.toLowerCase() === 'ul') {
          submenu.style.display = 'none';
        }
      }
    },
  },
  beforeUnmount() {
    document.removeEventListener('mouseover', this.handleMouseover);
  },
};
</script>
