<template>
  <div v-if="activeCart && activeCart.items.length > 0">
    <pos-bottom :total="total" @cancel="clearCart" @hold="onHold" @pay="onPay"/>
  </div>
  <pos-menu
      :count="cartItems && cartItems.length > 0 ? cartItems.filter(item => item.transactionId !== activeCartId).length : 0"
      @open="openDrawer"/>

  <pos-orders v-model:is-open="isDrawerOpen" :active-order="activeCartId" :held-orders="cartItems"
              @activate="setActiveCart"
              @complete="clearCartNotActive"/>

  <div class="page-wrapper pos-pg-wrapper ms-0">
    <div class="content pos-design p-0">

      <div class="row align-items-start pos-wrapper">
        <div class="col-md-12 col-lg-8">
          <div class="pos-categories tabs_wrapper">
            <h5>Categories</h5>
            <p>Select From Below Categories</p>
            <ul class="tabs owl-carousel pos-category">
              <Carousel
                  :breakpoints="breakpoints"
                  :settings="settings"
                  :wrap-around="true"
              >
                <!--                <slide>-->
                <!--                   <li id="all" key="100">-->
                <!--                    <a>-->
                <!--                      <img-->
                <!--                          :src="require(`@/assets/img/categories/category-01.png`)"-->
                <!--                          alt="Categories"-->
                <!--                      />-->
                <!--                    </a>-->
                <!--                    <h6>-->
                <!--                      <a href="javascript:void(0);">All Categories</a>-->
                <!--                    </h6>-->
                <!--                     <span>6</span>-->
                <!--                  </li>-->
                <!--                </slide>-->
                <Slide v-for="item in categoryProducts" :key="item.categoryId"
                       :class="` ${item.categoryId === selectedCategory ? 'bg-primary text-white' : ''}`">
                  <li :id="item.categoryName" @click="changeCategory(item.categoryId)">
                    <a>
                      <img
                          :src="require(`@/assets/img/categories/${item.categoryImage}`)"
                          alt="Categories"
                      />
                    </a>
                    <h6>
                      <a :class="` ${item.categoryId === selectedCategory ? 'text-white' : ''}`">{{
                          item.categoryName
                        }}</a>
                    </h6>
                    <span>{{ item.numberOfProduct }} items</span>
                    <!--                    <span>{{ item.Items }}</span>-->
                  </li>
                </Slide>
                <template #addons>
                  <Navigation/>
                </template>
              </Carousel>
            </ul>
            <div class="pos-products">
              <div class="d-flex align-items-center justify-content-between">
                <h5 class="mb-3">Products</h5>
              </div>
              <div class="tabs_container">
                <div class="tab_content active" data-tab="all">
                  <div v-if="isLoading" class="loading-container">
                    <div class="loader"></div>
                    <span class="loading-text">Loading products...</span>
                  </div>
                  <div v-else-if="products.length > 0" class="row">
                    <div v-for="product in products" :id="product.productId"
                         :key="product.productId"
                         :class="`col-sm-2 col-md-6 col-lg-3 col-xl-3 ${product.productId === 1 ? '' : 'pe-2'}`">
                      <div
                          :class="`product-info ${isProductInCart(product.productId) ? 'active': ''} default-cover card`">
                        <a :class="`img-bg ${isProductInCart(product.productId) ? 'cursor-not-allowed' : ''} `"
                           :disabled="isProductInCart(product.productId)"
                           @click="addToCart(product)">
                          <img :src="require(`@/assets/img/products/${product.image}`)" :width="100" alt="product"/>
                          <span><vue-feather class="feather-16" type="check"></vue-feather></span>
                        </a>
                        <h6 class="cat-name">
                          <a>{{ product.category.categoryName }}</a>
                        </h6>
                        <h6 class="product-name">
                          <a>{{ product.productName }}</a>
                        </h6>
                        <div
                            class="d-flex align-items-center justify-content-between price"
                        >
                          <span>{{ product.quantity }} {{ product.unit.unitSymbol }}</span>
                          <p>${{ product.specialPrice }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else-if="noMoreProducts">No more products to load.</div>
                  <div v-else>No products found.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--        ORDER LIST-->
        <div class="col-md-12 col-lg-4 ps-0">
          <aside class="product-order-list">
            <div v-if="activeCart && activeCart.items.length > 0"
                 class="head d-flex align-items-center justify-content-between w-100">
              <div class="">
                <h5>Order List</h5>
                <span>Transaction ID : #{{ activeCartId }}</span>
              </div>
              <div class="">
                <!--                <a-->
                <!--                    class="confirm-text"-->
                <!--                    href="javascript:void(0);"-->
                <!--                    @click="showConfirmation"-->
                <!--                >-->
                <!--                  <vue-feather-->
                <!--                      class="feather-16 text-danger"-->
                <!--                      type="trash-2"-->
                <!--                  ></vue-feather-->
                <!--                  >-->
                <!--                </a>-->
                <a class="layout-box" @click="changeView">
                  <vue-feather :type="`${listView ? 'list' : 'layout'}`" class="layout"></vue-feather
                  >
                </a>
              </div>
            </div>
            <div class="customer-info block-section">
              <h6>Customer Information</h6>
              <div class="input-block d-flex align-items-center">
                <div class="flex-grow-1">
                  <vue-select
                      id="walkin"
                      :options="Walk"
                      placeholder="Walk in Customer"
                  />
                </div>
                <a
                    class="btn btn-primary btn-icon"
                    data-bs-target="#create"
                    data-bs-toggle="modal"
                    href="javascript:void(0);"
                >
                  <vue-feather class="feather-16" type="user-plus"></vue-feather
                  >
                </a>
              </div>
              <div class="input-block">
                <vue-select
                    v-model="selectedProduct"
                    :options="select2Options"
                    :settings="select2Settings"
                    @close="onClose"
                    @open="onOpen"
                    @search="onSearch"
                    @select="onSelect"
                />
              </div>
            </div>
            <div v-if="activeCart && activeCart.items.length > 0" class="product-added block-section">
              <div class="head-text d-flex align-items-center justify-content-between">
                <h6 class="d-flex align-items-center mb-0">
                  Product Added<span class="count">{{ activeCart.items.length }}</span>
                </h6>
                <a
                    class="d-flex align-items-center text-danger"
                    @click="clearCart"><span class="me-1"
                ><vue-feather class="feather-16" type="x"></vue-feather></span
                >Clear all</a
                >
              </div>

              <!--            cart in table -->

              <div v-if="listView">
                <table class="table cart-table">
                  <thead>
                  <tr>
                    <th>Product</th>
                    <th>Price</th>
                    <th>Quantity</th>
                    <!--          <th>Total</th>-->
                    <th>Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="item in activeCart.items" :key="item?.productId">
                    <td>{{ item?.productName }}</td>
                    <td>${{ item?.price.toFixed(2) }}</td>
                    <td>
                      <div class="quantity-controls">
                        <button class="btn btn-sm btn-outline-secondary" @click="decrementQuantity(item?.productId)">-
                        </button>
                        <span class="quantity-display">{{ item?.quantityInCart }}</span>
                        <button class="btn btn-sm btn-outline-secondary" @click="incrementQuantity(item?.productId)">+
                        </button>
                      </div>
                    </td>
                    <!--          <td>${{ (item.price * item.quantityInCart).toFixed(2) }}</td>-->
                    <td>
                      <button class="btn btn-sm btn-danger" @click="removeFromCart(item?.productId)">Remove</button>
                    </td>
                  </tr>
                  <tr v-if="!activeCart">
                    <td class="text-center" colspan="5">No items in cart.</td>
                  </tr>
                  </tbody>
                  <tfoot>
                  <tr>
                    <td class="text-end fw-bold" colspan="3">Subtotal:</td>
                    <td colspan="2">${{ subtotal.toFixed(2) }}</td>
                  </tr>
                  <tr>
                    <td class="text-end fw-bold" colspan="3">Tax (10%):</td>
                    <td colspan="2">${{ tax.toFixed(2) }}</td>
                  </tr>
                  <tr>
                    <td class="text-danger text-end fw-bold" colspan="3">Discount (5%):</td>
                    <td class="text-danger" colspan="2">-${{ discount.toFixed(2) }}</td>
                  </tr>
                  <tr>
                    <td class="text-end fw-bold" colspan="3">Total:</td>
                    <td class="fw-bold" colspan="2">${{ total.toFixed(2) }}</td>
                  </tr>
                  </tfoot>
                </table>
              </div>

              <!--            Close cart in table-->


              <!--              Cart view image-->
              <div v-else class="product-wrap">
                <div v-for="item in activeCart.items" :key="item?.productId"
                     class="product-list d-flex align-items-center justify-content-between">
                  <div
                      class="d-flex align-items-center product-info"
                      data-bs-target="#products"
                      data-bs-toggle="modal"
                  >
                    <a class="img-bg">
                      <img
                          :src="require(`@/assets/img/products/${item?.image}`)"
                          alt="Products"
                      />
                    </a>
                    <div class="info">
                      <span>{{ item?.sku }}</span>
                      <h6><a href="javascript:void(0);">{{ item?.productName }}</a></h6>
                      <p>${{ item?.specialPrice }} X {{ item?.quantityInCart }}</p>
                    </div>
                  </div>
                  <div class="qty-item text-center">
                    <a
                        class="dec d-flex justify-content-center align-items-center"
                        data-bs-placement="top"
                        data-bs-toggle="tooltip"
                        title="minus"
                        @click="decrementQuantity(item?.productId)"
                    >
                      <vue-feather class="feather-14" type="minus-circle"></vue-feather
                      >
                    </a>
                    <input
                        :value="item?.quantityInCart"
                        class="form-control text-center"
                        name="qty"
                        type="text"
                    />
                    <a
                        class="inc d-flex justify-content-center align-items-center"
                        data-bs-placement="top"
                        data-bs-toggle="tooltip"
                        title="plus"
                        @click="incrementQuantity(item?.productId)"
                    >
                      <vue-feather class="feather-14" type="plus-circle"></vue-feather
                      >
                    </a>
                  </div>
                  <div class="d-flex align-items-center action">
                    <a
                        class="btn-icon edit-icon me-2"
                        data-bs-target="#edit-product"
                        data-bs-toggle="modal"
                    >
                      <vue-feather class="feather-14" type="edit"></vue-feather>
                    </a>
                    <a
                        class="btn-icon delete-icon confirm-text"
                        @click="removeFromCart(item?.productId)"
                    >
                      <vue-feather class="feather-14" type="trash-2"></vue-feather>
                    </a>
                  </div>
                </div>
              </div>
              <!--              close product view image-->
            </div>
            <div v-if="activeCart && activeCart.items.length > 0" class="block-section">
              <!--              <div class="selling-info">-->
              <!--                <div class="row">-->
              <!--                  <div class="col-12 col-sm-4">-->
              <!--                    <div class="input-block">-->
              <!--                      <label>Order Tax</label>-->
              <!--                      <vue-select id="gst" :options="GST" placeholder="GST 5%"/>-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                  <div class="col-12 col-sm-4">-->
              <!--                    <div class="input-block">-->
              <!--                      <label>Shipping</label>-->
              <!--                      <vue-select id="shippingf" :options="Shipping" placeholder="15"/>-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                  <div class="col-12 col-sm-4">-->
              <!--                    <div class="input-block">-->
              <!--                      <label>Discount</label>-->
              <!--                      <vue-select id="discountf" :options="Discount" placeholder="10%"/>-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </div>-->
              <div v-if="!listView" class="order-total">
                <table class="table table-responsive table-borderless">
                  <tr>
                    <td>Sub Total</td>
                    <td class="text-end">${{ subtotal.toFixed(2) }}</td>
                  </tr>
                  <tr>
                    <td>Tax (GST 5%)</td>
                    <td class="text-end">${{ tax.toFixed(2) }}</td>
                  </tr>
                  <tr>
                    <td>Shipping</td>
                    <td class="text-end">$40.21</td>
                  </tr>
                  <tr>
                    <td class="danger">Discount (10%)</td>
                    <td class="danger text-end">-${{ discount.toFixed(2) }}</td>
                  </tr>
                  <tr>
                    <td>Total</td>
                    <td class="text-end">${{ total.toFixed(2) }}</td>
                  </tr>
                </table>
              </div>
            </div>
            <div v-if="activeCart && activeCart.items.length > 0" class="block-section payment-method">
              <h6>Payment Method</h6>
              <div class="row d-flex align-items-center justify-content-center methods">
                <div v-for="item in paymentMethods" :key="item.id"
                     :class="`col-md-6 col-lg-4 item ${item.id === selectedPaymentMethod ? 'bg-orange-gradient': ''} active`">
                  <div class="default-cover">
                    <a @click="changePaymentMethod(item.id)">
                      <img :class="`${item.id === selectedPaymentMethod ? 'text-white': ''}`"
                           :src="require(`@/assets/img/icons/${item?.icon}`)"
                           alt="Payment Method"/>
                      <span :class="`${item.id === selectedPaymentMethod ? 'text-white': ''}`">{{ item.text }}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <!--            <div v-if="cartItems.length > 0" class="d-grid btn-block">-->
            <!--              <a class="btn btn-secondary" href="javascript:void(0);">-->
            <!--                Grand Total : ${{ cartTotal }}-->
            <!--              </a>-->
            <!--            </div>-->
            <!--            <div v-if="cartItems.length > 0" class="btn-row d-sm-flex align-items-center justify-content-between">-->
            <!--              <a-->
            <!--                href="javascript:void(0);"-->
            <!--                class="btn btn-info btn-icon flex-fill"-->
            <!--                data-bs-toggle="modal"-->
            <!--                data-bs-target="#hold-order"-->
            <!--                ><span class="me-1 d-flex align-items-center"-->
            <!--                  ><vue-feather type="pause" class="feather-16 me-1"></vue-feather></span-->
            <!--                >Hold</a-->
            <!--              >-->
            <!--              <a href="javascript:void(0);" class="btn btn-danger btn-icon flex-fill"-->
            <!--                ><span class="me-1 d-flex align-items-center"-->
            <!--                  ><vue-feather-->
            <!--                    type="trash-2"-->
            <!--                    class="feather-16 me-1"-->
            <!--                  ></vue-feather></span-->
            <!--                >Void</a-->
            <!--              >-->
            <!--              <a-->
            <!--                href="javascript:void(0);"-->
            <!--                class="btn btn-success btn-icon flex-fill"-->
            <!--                data-bs-toggle="modal"-->
            <!--                data-bs-target="#payment-completed"-->
            <!--                ><span class="me-1 d-flex align-items-center"-->
            <!--                  ><vue-feather-->
            <!--                    type="credit-card"-->
            <!--                    class="feather-16 me-1"-->
            <!--                  ></vue-feather></span-->
            <!--                >Payment</a-->
            <!--              >-->
            <!--            </div>-->

            <div v-if="!activeCart" class="block-section">
              Your cart is empty.
            </div>

          </aside>
        </div>

        <!--        LOTTIE-->

        <div v-if="anim" class="lottie-overlay">
          <LottieAnimation
              :animation-data="PaymentCompleted"
              :auto-play="true"
              :loop="false"
              :speed="1"
              class="lottie-animation"
              @complete="complete"
              @enterFrame="enterFrame"
              @loopComplete="loopComplete"
              @segmentStart="segmentStart"
              @stopped="stopped"/>
        </div>

      </div>
    </div>
  </div>

  <div v-if="activeCart && activeCart.items.length > 0">
    <PosModal
        :bill="total"
        :discount="discount"
        :items="activeCart.items"
        :on-hold="setActiveCart"
        :subtotal="subtotal"
        :tax="tax"
        :total="total"/>
  </div>

</template>

<script>
import {Carousel, Navigation, Slide} from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import Swal from "sweetalert2";
import {LottieAnimation} from "lottie-web-vue";
import {productService} from "@/services/products.service";
import {computed, onMounted, onUnmounted, reactive, ref, watch} from 'vue';
import {useCartStore} from "@/stores/cart";
import {storeToRefs} from 'pinia';
import {categoryService} from "@/services/categories.service";
import PosModal from "@/components/modal/pos-modal.vue";
import PosBottom from "@/views/pages/sales/pos-bottom.vue";
import PosMenu from "@/views/pages/sales/pos-menu.vue";
import PosOrders from "@/views/pages/sales/pos-orders.vue";
import {ordersService} from "@/services/orders.service";
import PaymentCompleted from "@/assets/json/animation/Animation-1735313097089.json"

export default {
  data() {
    return {
      Walk: ["Walk in Customer", "John", "Smith", "Ana", "Elza"],
      GST: ["GST 5%", "GST 10%", "GST 15%", "GST 20%", "GST 25%", "GST 30%"],
      Shipping: ["15", "20", "25", "30"],
      Discount: ["10%", "10%", "15%", "20%", "25%", "30%"],
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        loop: true,
      },
      breakpoints: {
        575: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        767: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        991: {
          itemsToShow: 4,
          snapAlign: "center",
        },
        1024: {
          itemsToShow: 5,
          snapAlign: "start",
        },
      },
    };
  },
  components: {
    PosOrders,
    PosMenu,
    PosBottom,
    Carousel,
    Slide,
    Navigation,
    PosModal,
    LottieAnimation
  },
  methods: {
    showConfirmation() {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          Swal.fire({
            icon: "success",
            title: "Deleted!",
            text: "Your file has been deleted.",
            confirmButtonClass: "btn btn-success",
          });
        }
      });
    },
  },
  setup() {
    // Searches
    const selectedProduct = ref(null);
    const selectedProductData = ref(null);
    const productOptions = ref([]);
    const select2Options = reactive([]);
    const isSearchLoading = ref(false);
    let anim = ref(false)

    // Pagination's
    const products = ref([]);
    const isLoading = ref(false);
    const noMoreProducts = ref(false);
    let currentPage = 1;
    const productsPerPage = 12;

    const categories = ref([]);

    const paymentMethods = ref([
      {id: 1, text: "Cash", value: "CASH", icon: "cash-pay.svg"},
      {id: 2, text: "Debit Card", value: "DEBIT_CARD", icon: "credit-card.svg"},
      {id: 3, text: "Scan", value: "SCAN", icon: "qr-scan.svg"}
    ]);

    const selectedPaymentMethod = ref(null);

    const selectedCategory = ref(null);

    const listView = ref(true);

    //Sores
    const cartStore = useCartStore();

    // Using storeToRefs for reactivity (recommended)
    const {carts, activeCartId} = storeToRefs(cartStore);

    const cartItems = computed(() => cartStore.carts);

    // Computed property to get the active cart
    const activeCart = computed(() => {
      if (!activeCartId.value) return null; // Handle case where no cart is active
      return carts.value.find(cart => cart.transactionId === activeCartId.value);
    });

    const setActiveCart = (cartId) => {
      cartStore.setActiveCart(cartId);
      isDrawerOpen.value = false;
    }

    const subtotal = computed(() => {
      return activeCart.value.items.reduce((sum, item) => sum + (item.price * item.quantityInCart), 0);
    });

    const tax = computed(() => {
      return subtotal.value * 0.1;
    });
    const discount = computed(() => {
      return subtotal.value * 0.05;
    });

    const total = computed(() => {
      return subtotal.value + tax.value - discount.value;
    });

    const categoryProducts = computed(() => {
      return categories.value.filter(item => item.numberOfProduct > 0);
    });

    const changeView = () => {
      listView.value = !listView.value;
    };

    const changePaymentMethod = (paymentMethod) => {
      selectedPaymentMethod.value = paymentMethod;
    };

    const changeCategory = (category) => {
      selectedCategory.value = category;
    };

    const addToCart = (product) => {
      if (activeCart.value) {
        cartStore.addItem(activeCartId.value, product); // Assuming you have an addItem action
      } else {
        cartStore.createCart(); // Assuming you have a createCart action
        cartStore.addItem(cartStore.activeCartId, product);
      }
    };
    const removeFromCart = (productId) => {
      cartStore.removeItem(activeCartId.value, productId);
    };

    const incrementQuantity = (productId) => {
      cartStore.incrementItemQuantity(activeCartId.value, productId);
    };

    const decrementQuantity = (productId) => {
      cartStore.decrementItemQuantity(activeCartId.value, productId);
    };

    const isProductInCart = (productId) => {
      if (!activeCart.value) return false; // Handle case where no cart is active
      return activeCart.value.items.some(item => item.productId === productId);
    };

    const clearCart = () => {
      cartStore.removeCart(activeCartId.value);
    };

    const clearCartNotActive = (transactionId) => {
      cartStore.removeCart(transactionId);
    };

    const fetchData = () => {
      if (isLoading.value || noMoreProducts.value) return;
      isLoading.value = true;

      productService.getAllproducts(currentPage - 1, productsPerPage).then(data => {
            if (data.content.length === 0) {
              noMoreProducts.value = true;
            } else {
              products.value.push(...data.content);
              currentPage++;
            }
          }
      ).catch(error => console.error('Error:', error))
          .finally(() => isLoading.value = false);
    };

    const fetchCategories = () => {
      categoryService.getAllCategory(currentPage - 1, 20).then(data => categories.value.push(...data.content)
      ).catch(error => console.error('Error:', error))
    };

    const select2Settings = reactive({
      placeholder: 'Search for a product',
      allowClear: true,
      ajax: {
        delay: 500,
        transport: async (params, success, failure) => {
          isSearchLoading.value = true;
          productService.getProductsByKeywords(params.data.term, 0, 5).then(data => success(data.content))
              .catch(error => {
                failure(error);
              })
              .finally(() => isSearchLoading.value = false);
        },
        processResults: (data) => {
          productOptions.value.push(...data);
          return {
            results: data.map((product) => ({
              id: product.productId,
              text: product.productName,
              description: product.description
            })),
          };
        },
        cache: true
      },
      minimumInputLength: 3
    });

    const onSelect = async (event) => {
      selectedProduct.value = event.id;
      selectedProductData.value = productOptions.value.find(product => product.productId === event.id);
      addToCart(selectedProductData.value);
      productOptions.value = [];
      select2Options.length = 0;
    };

    const onClose = () => {
      console.log('close');
    };

    const onOpen = () => {
      console.log('open');
    };

    const onSearch = (query) => {
      console.log('search', query);
    };

    const handleScroll = () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 500) { // Adjust 500 for early loading
        fetchData();
      }
    };

    const holdCart = () => {
      alert("Cart on Hold");
    }
    const cancel = () => {
      alert("Transaction Cancelled");
    }

    const proceedToPayment = () => {
      alert("Proceeding to payment");
    };

    const onHold = () => {
      console.log('Transaction held');
    };

    const onPay = () => {
      const body = activeCart.value.items.reduce((acc, product) => {
        acc.push({
          "productId": product.productId,
          "quantity": product.quantityInCart,
        });
        return acc;
      }, []);

      ordersService.placeBatchOrder({"cartItems": body, "paymentMethod": "CASH"})
          .then(() => {
            // this.$refs.anim.play() // .play, .pause, .stop available
             anim.value = true
             clearCart();
            // this.$router.push("/inventory/product-list");
            // this.$emit('refetch-products');
          }).catch(error => console.error('Error adding to cart:', error));

    };

    const isDrawerOpen = ref(false);
    const heldOrders = ref([
      {id: '12345', total: 55.75, items: [{id: 1}, {id: 2}]},
      {id: '67890', total: 120.00, items: [{id: 3}, {id: 4}, {id: 5}]},
      {id: '98765', total: 200, items: [{id: 6}, {id: 7}, {id: 8}, {id: 9}]},
    ]);

    const toggleDrawer = () => {
      isDrawerOpen.value = !isDrawerOpen.value;
    };

    const openDrawer = () => {
      isDrawerOpen.value = true
    }

    onMounted(() => {
      fetchCategories()
      fetchData();
      window.addEventListener('scroll', handleScroll);
      const storedCart = localStorage.getItem('carts');
      const storedActiveCartId = localStorage.getItem('cartId');
      if (storedCart) {
        cartStore.setCart(JSON.parse(storedCart), storedActiveCartId);
      }
    });

    watch(cartItems, (newCart) => {
      // Save to local storage whenever the cart changes
      localStorage.setItem('carts', JSON.stringify(newCart));
    }, {deep: true});

    watch(activeCartId, (newCartId) => {
      // Save to local storage whenever the cart changes
      localStorage.setItem('cartId', newCartId);
    }, {deep: true});

    watch(productOptions, () => {
      select2Options.length = 0;
      select2Options.push(...productOptions.value)
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll);
    });

    // called after each loop
    const loopComplete = () => {
      console.log('Loop complete')
    }

// called after first loop
    const complete = () => {
      console.log('First loop complete')
      anim.value = false;
    }

// called after first frame entered
    const enterFrame = () => {
      console.log('Entered first frame')
    }

// called after segment started
    const segmentStart = () => {
      console.log('Segment started')
    }

// called after animation stopped
    const stopped = () => {
      console.log('Stopped')
    }

    return {
      loopComplete,
      stopped,
      segmentStart,
      enterFrame,
      complete,
      decrementQuantity,
      incrementQuantity,
      removeFromCart,
      addToCart,
      isProductInCart,
      clearCart,
      proceedToPayment,
      fetchData,
      onSelect,
      onClose,
      holdCart,
      changeView,
      cancel,
      onOpen,
      onSearch,
      setActiveCart,
      changePaymentMethod,
      changeCategory,
      onHold,
      onPay,
      toggleDrawer,
      openDrawer,
      clearCartNotActive,
      anim,
      PaymentCompleted,
      isDrawerOpen,
      heldOrders,
      categoryProducts,
      selectedCategory,
      selectedPaymentMethod,
      select2Options,
      paymentMethods,
      select2Settings,
      selectedProduct,
      selectedProductData,
      productOptions,
      products,
      isLoading,
      activeCart,
      activeCartId,
      isSearchLoading,
      noMoreProducts,
      subtotal,
      categories,
      total,
      tax,
      discount,
      cartItems,
      listView
    }
  },
};
</script>

<style scoped>
.loading-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  min-height: 100px; /* Ensure some space is occupied */
}

.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite; /* Animate the loader */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-text {
  margin-left: 10px; /* Add some spacing between the spinner and the text */
}

.btn-group .btn {
  margin-left: 10px; /* Spacing between buttons */
  font-weight: 500;
}

.content {
  padding-top: 80px; /* adjust this value to the height of your sticky bar */
}

.cart-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.cart-table th,
.cart-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.cart-table th {
  background-color: #f0f0f0;
  font-weight: bold;
}

.quantity-controls {
  display: flex;
  align-items: center;
}

.quantity-display {
  margin: 0 5px;
  min-width: 2em;
  text-align: center;
}

.main-content {
  transition: padding-right 0.3s ease;
}

.lottie-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7); /* Semi-transparent white background */
  z-index: 2; /* Overlay is on top */
}

.lottie-animation {
  max-width: 400px;
  max-height: 400px;
}
</style>



