export  default function generateUniqueId(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;

  // More robust random number generation using crypto.getRandomValues if available
  if (typeof window.crypto !== 'undefined' && typeof window.crypto.getRandomValues === 'function') {
    let randomValues = new Uint32Array(length);
    window.crypto.getRandomValues(randomValues);

    for ( let i = 0; i < length; i++ ) {
      result += characters.charAt(randomValues[i] % charactersLength);
    }
  } else { // Fallback to Math.random (less secure but works in all browsers)
    for ( let i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
  }
  return result;
}
