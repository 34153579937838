<template>
  <div>
    <button @click="generateInvoice">Generate Invoice</button>
    <div v-if="pdfPreviewUrl" class="pdf-preview-container">
      <button @click="closePreview">Close Preview</button>
      <iframe :src="pdfPreviewUrl" class="pdf-preview"></iframe>
    </div>
  </div>
</template>

<script>
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import dayjs from 'dayjs';
import logo from '@/assets/img/logo.png';

export default {
 data() {
    return {
      invoiceData: {
        invoiceNumber: 'INV-2024-1001',
        invoiceDate: '2024-10-26',
        dueDate: '2024-11-26',
        clientName: 'Acme Corporation',
        clientAddress: '123 Main St, Anytown',
        items: [
          { description: 'Consulting Services', quantity: 2, unitPrice: 500 },
          { description: 'Web Development', quantity: 1, unitPrice: 2000 },
          { description: 'Project Management', quantity: 3, unitPrice: 250 },
        ],
        taxRate: 0.10, // 10% tax
        couponCode: 'SAVE10', // Example coupon code
        discountPercentage: 0.05, // 5% discount
        notes: 'Thank you for your business!',
      },
      pdfPreviewUrl: null,
    };
  },
   computed: {
    subTotal() {
      return this.invoiceData.items.reduce((sum, item) => sum + item.quantity * item.unitPrice, 0);
    },
    taxAmount() {
      return this.subTotal * this.invoiceData.taxRate;
    },
    discountAmount() {
        if (this.invoiceData.couponCode === 'SAVE10') {
            return this.subTotal * this.invoiceData.discountPercentage;
        }
        return 0;
    },
    totalAmount() {
        return this.subTotal + this.taxAmount - this.discountAmount;
    }
  },
  methods: {
    generateInvoice() {
      const doc = new jsPDF();

      // Header (Logo and Company Info)
      doc.addImage(logo, 'PNG', 14, 10, 50, 20);
      doc.setFontSize(12);
      doc.text('Your Company Name', 70, 20);
      doc.text('Your Company Address', 70, 27);

      let totalPagesExp = '{total_pages_count_string}';

      // Invoice Details
      doc.setFontSize(14);
      doc.text('Invoice', 14, 40);
      doc.setFontSize(10);
      doc.text(`Invoice #: ${this.invoiceData.invoiceNumber}`, 14, 48);
      doc.text(`Date: ${dayjs(this.invoiceData.invoiceDate).format('MMMM DD, YYYY')}`, 14, 53);
      doc.text(`Due Date: ${dayjs(this.invoiceData.dueDate).format('MMMM DD, YYYY')}`, 14, 58);

      // Client Information
      doc.setFontSize(12);
      doc.text('Bill to:', 14, 70);
      doc.setFontSize(10);
      doc.text(this.invoiceData.clientName, 14, 78);
      doc.text(this.invoiceData.clientAddress, 14, 83);

       // Items Table
      const itemHeaders = ['Description', 'Quantity', 'Unit Price', 'Total'];
      const itemBody = this.invoiceData.items.map(item => [
        item.description,
        item.quantity,
        item.unitPrice.toLocaleString('en-US', {style: 'currency', currency: 'USD'}),
        (item.quantity * item.unitPrice).toLocaleString('en-US', {style: 'currency', currency: 'USD'}),
      ]);

       doc.autoTable({
        head: [itemHeaders],
        body: itemBody,
        startY: 90,
        didParseCell: function (data) {
            if (data.column.index === 2 || data.column.index === 3) {
                data.cell.styles.halign = 'right';
            }
        },
        footStyles: {fontStyle: 'bold'},
        didDrawFooter: function(data) {
            const doc = data.doc;
            const totalPagesExp = '{total_pages_count_string}';
            let str = "Page " + doc.internal.getNumberOfPages();
            if (typeof doc.putTotalPages === 'function') {
              str = str + " of " + totalPagesExp;
            }
            doc.setFontSize(10);
            doc.text(str, data.settings.margin.left, doc.internal.pageSize.height - 10);
        },
        didDrawLastPage: function(data){
            const doc = data.doc;
            if (typeof doc.putTotalPages === 'function') {
              doc.putTotalPages(totalPagesExp);
            }
        }
      });

      // Totals Section
      let finalY = doc.previousAutoTable.finalY + 10;
      doc.setFontSize(12);
      doc.text(`Subtotal: ${this.subTotal.toLocaleString('en-US', {style: 'currency', currency: 'USD'})}`, 14, finalY);
      finalY += 5;
      doc.text(`Tax (${(this.invoiceData.taxRate * 100).toFixed(0)}%): ${this.taxAmount.toLocaleString('en-US', {style: 'currency', currency: 'USD'})}`, 14, finalY);
      finalY += 5;
      if (this.invoiceData.couponCode === 'SAVE10') {
          doc.text(`Discount (${(this.invoiceData.discountPercentage * 100).toFixed(0)}% - ${this.invoiceData.couponCode}): -${this.discountAmount.toLocaleString('en-US', {style: 'currency', currency: 'USD'})}`, 14, finalY);
          finalY += 5;
      }
      doc.setFontSize(14);
      doc.text(`Total: ${this.totalAmount.toLocaleString('en-US', {style: 'currency', currency: 'USD'})}`, 14, finalY);

      // Notes
      doc.setFontSize(10);
      doc.text('Notes:', 14, finalY + 10);
      doc.text(this.invoiceData.notes, 14, finalY + 15);

      this.pdfPreviewUrl = doc.output('datauristring');
    },
    closePreview() {
      this.pdfPreviewUrl = null;
    },
  },
};
</script>

<style scoped>
.pdf-preview-container {
  position: relative;
  width: 100%;
  overflow: auto;
}

.pdf-preview {
  width: 100%;
  height: 80vh;
  border: 1px solid #ccc;
  transform: scale(0.7);
  transform-origin: top left;
}

.pdf-preview-container button {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
}
</style>
