import {handleResponse} from "@/utils/handle-response";
import {requestOptions} from "@/utils/request-options";


export const unitService = {
    getAllUnits,
    getUnitById,
    addUnit
};

function getAllUnits(pageNumber, pageSize) {
    return fetch(`${process.env.VUE_APP_BASE_URL}/api/admin/units??pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=unitSymbol&sortOrder=asc`, requestOptions.get())
        .then(handleResponse)
}

function getUnitById(sku) {
   return fetch(`${process.env.VUE_APP_BASE_URL}/api/public/products/sku/${sku}`, requestOptions.get())
        .then(handleResponse)
}

function addUnit(unitSymbol, unitName, status) {
    return fetch(`${process.env.VUE_APP_BASE_URL}/api/admin/unit`, requestOptions.post(
      {
            "unitId": 0,
            "unitSymbol": unitSymbol,
            "unitName": unitName,
            "status": status
          }
    )).then(handleResponse).then(data => {return data;});
}
