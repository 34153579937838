<template>
  <!-- Add Unit -->
  <div class="modal fade" id="add-units">
    <div class="modal-dialog modal-dialog-centered custom-modal-two">
      <div class="modal-content">
        <div class="page-wrapper-new p-0">
          <div class="content">
            <div class="modal-header border-0 custom-modal-header">
              <div class="page-title">
                <h4>Create Unit</h4>
              </div>
              <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body custom-modal-body">
              <form @submit.prevent="submitForm">
                <div class="mb-3">
                  <label class="form-label" for="unitName">Name</label>
                  <input type="text" class="form-control" id="unitName" v-model="unitName" />
                </div>
                <div class="mb-3">
                  <label class="form-label" for="unitSymbol">Symbol</label>
                  <input type="text" class="form-control" id="unitSymbol" v-model="unitSymbol" />
                </div>
                <div class="mb-0">
                  <div class="status-toggle modal-status d-flex justify-content-between align-items-center">
                    <span class="status-label" for="status">Status</span>
                    <input type="checkbox" id="status" class="check" v-model="status" checked="" />
                    <label for="user2" class="checktoggle"></label>
                  </div>
                </div>
                <div class="modal-footer-btn">
                  <button type="button" class="btn btn-cancel me-2" data-bs-dismiss="modal">
                    Cancel
                  </button>
                  <button type="submit" class="btn btn-submit" data-bs-dismiss="modal">Create Unit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Unit -->

  <!-- Edit Unit -->
  <div class="modal fade" id="edit-units">
    <div class="modal-dialog modal-dialog-centered custom-modal-two">
      <div class="modal-content">
        <div class="page-wrapper-new p-0">
          <div class="content">
            <div class="modal-header border-0 custom-modal-header">
              <div class="page-title">
                <h4>Edit Unit</h4>
              </div>
              <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body custom-modal-body">
              <form @submit.prevent="submitForm">
                <div class="mb-3">
                  <label class="form-label">Name</label>
                  <input type="text" class="form-control" value="Piece" />
                </div>
                <div class="mb-3">
                  <label class="form-label">Short Name</label>
                  <input type="text" class="form-control" value="PC" />
                </div>
                <div class="mb-0">
                  <div class="status-toggle modal-status d-flex justify-content-between align-items-center">
                    <span class="status-label">Status</span>
                    <input type="checkbox" id="user3" class="check" checked="" />
                    <label for="user3" class="checktoggle"></label>
                  </div>
                </div>
                <div class="modal-footer-btn">
                  <button type="button" class="btn btn-cancel me-2" data-bs-dismiss="modal">
                    Cancel
                  </button>
                  <button type="submit" class="btn btn-submit">Save Changes</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {unitService} from "@/services/units.services";

export default {
  emits: ['refetch-units'],
  data() {
    return {
      unitName: '',
      unitSymbol: '',
      status: true
    }
  },
  methods: {
    submitForm() {
       unitService.addUnit(this.unitSymbol, this.unitName, this.status ? "ACTIVE" : "DEACTIVATED")
           .then(() => {
             this.$router.push("/inventory/units");
          this.$emit('refetch-units');
          }).catch(error => console.error('Error:', error));
    },
  },
}
</script>
