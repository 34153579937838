<template>
  <div class="account-page">
    <div class="account-content">
      <div class="login-wrapper login-new">
        <div class="container">
          <div class="login-content user-login">
            <div class="login-logo">
              <img src="@/assets/img/logo.png" alt="img" />
              <router-link to="/dashboard" class="login-logo logo-white">
                <img src="@/assets/img/logo-white.png" alt="" />
              </router-link>
            </div>
            <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
              <div class="login-userset">
                <div class="login-userheading">
                  <h3>Sign In</h3>
                  <h4>Access the Dreamspos panel using your email and passcode.</h4>
                </div>
                <div class="form-login">
                  <label class="form-label">Email Address</label>
                  <div class="form-addons">
                    <Field
                      name="email"
                      type="email"
                      v-model="data.email"
                      class="form-control"
                      :class="{ 'is-invalid': errors.email }"
                    />
                    <div class="invalid-feedback">{{ errors.email }}</div>
                    <div class="emailshow text-danger" id="email"></div>
                    <img src="@/assets/img/icons/mail.svg" alt="img" />
                  </div>
                </div>
                <div class="form-login">
                  <label>Password</label>
                  <div class="pass-group">
                    <Field
                      name="password"
                      :type="showPassword ? 'text' : 'password'"
                      v-model="data.password"
                      class="form-control pass-input mt-2"
                      :class="{ 'is-invalid': errors.password }"
                    />
                    <span @click="toggleShow" class="toggle-password">
                      <i
                        :class="{
                          'fas fa-eye': showPassword,
                          'fas fa-eye-slash': !showPassword,
                        }"
                      ></i>
                    </span>
                    <div class="invalid-feedback">{{ errors.password }}</div>
                    <div class="emailshow text-danger" id="password"></div>
                  </div>
                </div>
                <div class="form-login authentication-check">
                  <div class="row">
                    <div class="col-6">
                      <div class="custom-control custom-checkbox">
                        <label class="checkboxs ps-4 mb-0 pb-0 line-height-1">
                          <input type="checkbox" />
                          <span class="checkmarks"></span>Remember me
                        </label>
                      </div>
                    </div>
                    <div class="col-6 text-end">
                      <router-link class="forgot-link" to="/forgot-password-3"
                        >Forgot Password?</router-link
                      >
                    </div>
                  </div>
                </div>
                <div class="form-login">
                  <button class="btn btn-login" type="submit">Sign In</button>
                </div>
                <div class="signinform">
                  <h4>
                    New on our platform?<router-link to="/register-3" class="hover-a">
                      Create an account</router-link
                    >
                  </h4>
                </div>
                <div class="form-setlogin or-text">
                  <h4>OR</h4>
                </div>
                <div class="form-sociallink">
                  <ul class="d-flex">
                    <li>
                      <a href="javascript:void(0);" class="facebook-logo">
                        <img src="@/assets/img/icons/facebook-logo.svg" alt="Facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">
                        <img src="@/assets/img/icons/google.png" alt="Google" />
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);" class="apple-logo">
                        <img src="@/assets/img/icons/apple-logo.svg" alt="Apple" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </Form>
          </div>
          <div
            class="my-4 d-flex justify-content-center align-items-center copyright-text"
          >
            <p>Copyright &copy; {{ new Date().getFullYear() }} Philodi. All rights reserved</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {reactive, ref} from "vue";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import {useAuthStore} from "@/stores/auth";
import { useRoute, useRouter } from 'vue-router';

export default {
  components: {
    Form,
    Field,
  },
  data() {
    return {
      showPassword: false,
      password: null,
      emailError: "",
      passwordError: "",
    };
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
  },
  setup() {
    const authStore = useAuthStore();

    const route = useRoute(); // Access the current route
    const router = useRouter(); // Access the router instance

    const data = reactive(
        {
          email: '',
          password: ''
        }
    )
    const schema = Yup.object().shape({
      email: Yup.string().required("Email is required").email("Email is invalid"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Password is required"),
    });

    const onSubmit = async (values) => {
      await authStore.authenticate(values.email, values.password);
      if(authStore.isAuthenticated) {
        const redirectPath = route.query.redirect || '/dashboard';
        await router.push(redirectPath.toString())
      }
    };

    return {
      data,
      schema,
      onSubmit,
      checked: ref(false),
    };
  },
};
</script>
