// src/stores/auth.js
import { defineStore } from 'pinia';
import {authenticationService} from "@/services/authentication.service";

export const useAuthStore = defineStore('auth', {
  state: () => ({
    isAuthenticated: false,
    userToken: null,
  }),
  actions: {
    async authenticate(email, password) {
      const data = await authenticationService.login(email, password);
      if (data) {
        this.userToken = data.access_token;
         this.isAuthenticated = true;
      }
    },
    logout() {
      this.isAuthenticated = false;
      this.userToken = null;
    },
  },
});
