import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import {formattedDate, format_date} from "@/utils/date-formatter";
import logo from '@/assets/img/logo.png';

export const fileExportService = {
    pdfExport,
    excelExport,
    csvExport
};

function pdfExport(columns, headers, data, name, columnStyles, title) {
  const doc = new jsPDF();
  const date = new Date();

  // Add logo
  doc.addImage(logo, 'PNG', 14, 10, 50, 20); // x, y, width, height

  // Add title
  doc.setFontSize(18); // Set font size for the title
  doc.text(title, 14, 40); // Position the title (x, y coordinates)

  // Add date
  doc.setFontSize(10);
  doc.text(`Generated on: ${formattedDate(date)}`, 14, 47); // Position the date below the title

   // Format the date here using dayjs before passing to autoTable
  const formattedData = data.map(item => {
    const formattedDate = format_date(item.createdDate);
    return {
      ...item,
      createdDate: formattedDate
    }
  });

  const head = [columns];
  const body = formattedData.map(item => headers.map(header => item[header]));

  let totalPagesExp = '{total_pages_count_string}';

  doc.autoTable({
    head: head,
    body: body,
    startY: 55, // Start table below title and date
     headStyles: { // Style the header
        fillColor: [255, 165, 0], // Green color (RGB)
        textColor: [255, 255, 255], // White text
        fontStyle: 'bold' // Bold text,
     },
     columnStyles: columnStyles,
    didDrawPage: function (data) {
      let str = "Page " + doc.internal.getNumberOfPages();

      // Check if the plugin is available
      if (typeof doc.putTotalPages === 'function') {
        str = str + " of " + totalPagesExp;
      }
      doc.setFontSize(10);
      doc.text(str, data.settings.margin.left, doc.internal.pageSize.height - 10);
      },
    didDrawLastPage: function(data){
      if (typeof doc.putTotalPages === 'function') {
        doc.putTotalPages(totalPagesExp);
      }
    }
  });

  // doc.save(`${name}-${formattedDate(date)}.pdf`);
  return doc.output('datauristring');
}

function csvExport(headers, data, name) {
  const csvContent = "data:text/csv;charset=utf-8," +
        headers.join(",") + "\n" +
        data.map(e => headers.map(h => e[h.toLowerCase()]).join(",")).join("\n");

  const date = new Date();

  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", `${name}-${formattedDate(date)}.csv`);
  document.body.appendChild(link); // Required for Firefox
  link.click();
  document.body.removeChild(link);
}

function excelExport(array, name) {
 const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';

      const date = new Date();

      const ws = XLSX.utils.json_to_sheet(array);
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, `${name}-${formattedDate(date)}` + fileExtension);
}
