<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <div class="page-header">
        <div class="add-item d-flex">
          <div class="page-title">
            <h4>New Product</h4>
            <h6>Create new product</h6>
          </div>
        </div>
        <ul class="table-top-head">
          <li>
            <div class="page-btn">
              <router-link to="/inventory/product-list" class="btn btn-secondary"><vue-feather type="arrow-left"
                  class="me-2"></vue-feather>Back to
                Product</router-link>
            </div>
          </li>
          <li>
            <a ref="collapseHeader" data-bs-toggle="tooltip" data-bs-placement="top" title="Collapse"
              @click="toggleCollapse">
              <i data-feather="chevron-up" class="feather-chevron-up"></i>
            </a>
          </li>
        </ul>
      </div>
      <!-- /add -->
      <form @submit.prevent="submitForm">
        <div class="card">
          <div class="card-body add-product pb-0">
            <div class="accordion-card-one accordion" id="accordionExample">
              <div class="accordion-item">
                <div class="accordion-header" id="headingOne">
                  <div class="accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                    aria-controls="collapseOne">
                    <div class="addproduct-icon">
                      <h5>
                        <vue-feather type="info" class="add-info"></vue-feather><span>Product Information</span>
                      </h5>
                      <a href="javascript:void(0);"><vue-feather type="chevron-down"
                          class="chevron-down-add"></vue-feather></a>
                    </div>
                  </div>
                </div>
                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
<!--                    <div class="row">-->
<!--                      <div class="col-lg-4 col-sm-6 col-12">-->
<!--                        <div class="mb-3 add-product">-->
<!--                          <label class="form-label">Store</label>-->
<!--                          <vue-select :options="ThomasStore" id="thomasstore" placeholder="Choose" />-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <div class="col-lg-4 col-sm-6 col-12">-->
<!--                        <div class="mb-3 add-product">-->
<!--                          <label class="form-label">Warehouse</label>-->
<!--                          <vue-select :options="WarhouseStore" id="warehousestore" placeholder="Choose" />-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
                    <div class="row">
                      <div class="col-lg-4 col-sm-6 col-12">
                        <div class="mb-3 add-product">
                          <label class="form-label" for="productName">Product Name</label>
                          <input type="text" class="form-control" v-model="productName" />
                        </div>
                      </div>
                      <div class="col-lg-4 col-sm-6 col-12">
                        <div class="mb-3 add-product">
                          <label class="form-label" for="productSlug">Slug</label>
                          <input type="text" class="form-control" v-model="productSlug" />
                        </div>
                      </div>
                      <div class="col-lg-4 col-sm-6 col-12">
                        <div class="input-blocks add-product list">
                          <label>SKU</label>
                          <input type="text" class="form-control list" placeholder="Enter SKU" />
                          <button type="submit" class="btn btn-primaryadd">
                            Generate Code
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="addservice-info">
                      <div class="row">
                        <div class="col-lg-4 col-sm-6 col-12">
                          <div class="mb-3 add-product">
                            <div class="add-newplus">
                              <label class="form-label">Category</label>
                              <a href="javascript:void(0);" data-bs-toggle="modal"
                                data-bs-target="#add-units-category"><vue-feather type="plus-circle"
                                  class="plus-down-add"></vue-feather><span>Add New</span></a>
                            </div>
                            <vue-select :options="categories" v-model="category" id="choosenew" placeholder="Choose" />
                          </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-12">
                          <div class="mb-3 add-product">
                            <label class="form-label">Sub Category</label>
                            <vue-select :options="SubCategory" id="subcategory" placeholder="Choose" />
                          </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-12">
                          <div class="mb-3 add-product">
                            <label class="form-label">Sub Sub Category</label>
                            <vue-select :options="SubSubCategory" id="subsubcategory" placeholder="Choose" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="add-product-new">
                      <div class="row">
                        <div class="col-lg-4 col-sm-6 col-12">
                          <div class="mb-3 add-product">
                            <div class="add-newplus">
                              <label class="form-label">Brand</label>
                              <a href="javascript:void(0);" data-bs-toggle="modal"
                                data-bs-target="#add-units-brand"><vue-feather type="plus-circle"
                                  class="plus-down-add"></vue-feather><span>Add New</span></a>
                            </div>
                            <vue-select :options="brands" v-model="brand" placeholder="Choose" />
                          </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-12">
                          <div class="mb-3 add-product">
                            <div class="add-newplus">
                              <label class="form-label">Unit</label>
                              <a href="javascript:void(0);" data-bs-toggle="modal"
                                data-bs-target="#add-unit"><vue-feather type="plus-circle"
                                  class="plus-down-add"></vue-feather><span>Add New</span></a>
                            </div>
                            <vue-select :options="units" v-model="unit" id="chooseaddnew" placeholder="Choose" />
                          </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-12">
                          <div class="mb-3 add-product">
                            <label class="form-label">Selling Type</label>
                            <vue-select :options="SellingType" id="sellingtype" placeholder="Choose" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6 col-sm-6 col-12">
                        <div class="mb-3 add-product">
                          <label class="form-label">Barcode Symbology</label>
                          <vue-select :options="ChooseCode" id="choosecode" placeholder="Choose" />
                        </div>
                      </div>
                      <div class="col-lg-6 col-sm-6 col-12">
                        <div class="input-blocks add-product list">
                          <label>Item Code</label>
                          <input type="text" class="form-control list" placeholder="Please Enter Item Code" />
                          <button type="submit" class="btn btn-primaryadd">
                            Generate Code
                          </button>
                        </div>
                      </div>
                    </div>
                    <!-- Editor -->
                    <div class="col-lg-12">
                      <div class="input-blocks summer-description-box transfer mb-3">
                        <label for="description">Description</label>
                        <textarea class="form-control h-100" v-model="description" rows="5"></textarea>
                        <p class="mt-1">Maximum 60 Characters</p>
                      </div>
                    </div>
                    <!-- /Editor -->
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-card-one accordion" id="accordionExample2">
              <div class="accordion-item">
                <div class="accordion-header" id="headingTwo">
                  <div class="accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                    aria-controls="collapseTwo">
                    <div class="text-editor add-list">
                      <div class="addproduct-icon list icon">
                        <h5>
                          <vue-feather type="life-buoy" class="add-info"></vue-feather><span>Pricing & Stocks</span>
                        </h5>
                        <a href="javascript:void(0);"><vue-feather type="chevron-down"
                            class="chevron-down-add"></vue-feather></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="collapseTwo" class="accordion-collapse collapse show" aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample2">
                  <div class="accordion-body">
                    <div class="input-blocks add-products">
                      <label class="d-block" for="productType">Product Type</label>
                      <div class="single-pill-product">
                        <ul class="nav nav-pills" id="pills-tab1" role="tablist">
                          <li class="nav-item" role="presentation">
                            <span class="custom_radio me-4 mb-0 active" id="pills-home-tab" data-bs-toggle="pill"
                              data-bs-target="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">
                              <input type="radio" class="form-control" id="product1" v-model="productType"
                                value="SINGLE_PRODUCT" />
                              <span class="checkmark"><label for="product1"></label></span> Single Product</span>
                          </li>
                          <li class="nav-item" role="presentation">
                            <span class="custom_radio me-2 mb-0" id="pills-profile-tab" data-bs-toggle="pill"
                              data-bs-target="#pills-profile" role="tab" aria-controls="pills-profile"
                              aria-selected="false">
                              <input type="radio" class="form-control" id="product2" v-model="productType"
                                value="VARIABLE_PRODUCT" />
                              <span class="checkmark"><label for="product2"></label></span> Variable Product</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="tab-content" id="pills-tabContent">
                      <div class="tab-pane fade show active" id="pills-home" role="tabpanel"
                        aria-labelledby="pills-home-tab">
                        <div class="row">
                          <div class="col-lg-4 col-sm-6 col-12">
                            <div class="input-blocks add-product">
                              <label for="quantity">Quantity</label>
                              <input type="number" class="form-control" v-model="quantity" />
                            </div>
                          </div>
                          <div class="col-lg-4 col-sm-6 col-12">
                            <div class="input-blocks add-product">
                              <label for="price">Price</label>
                              <input type="number" class="form-control" v-model="price" />
                            </div>
                          </div>
                          <div class="col-lg-4 col-sm-6 col-12">
                            <div class="input-blocks add-product">
                              <label>Tax Type</label>
                              <vue-select :options="ExclusiveType" id="exclusivetype" placeholder="Exclusive" />
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-4 col-sm-6 col-12">
                            <div class="input-blocks add-product">
                              <label for="discountType">Discount Type</label>
                              <vue-select :options="ChooseTypeDis" v-model="discountType" id="choosetypedis"
                                placeholder="Choose" />
                            </div>
                          </div>
                          <div class="col-lg-4 col-sm-6 col-12">
                            <div class="input-blocks add-product">
                              <label for="discount">Discount Value</label>
                              <input type="number" class="form-control" placeholder="Choose" v-model="discount" />
                            </div>
                          </div>
                          <div class="col-lg-4 col-sm-6 col-12">
                            <div class="input-blocks add-product">
                              <label for="quantityAlert">Quantity Alert</label>
                              <input type="number" class="form-control" v-model="quantityAlert" />
                            </div>
                          </div>
                        </div>
                        <div class="accordion-card-one accordion" id="accordionExample3">
                          <div class="accordion-item">
                            <div class="accordion-header" id="headingThree">
                              <div class="accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseThree"
                                aria-controls="collapseThree">
                                <div class="addproduct-icon list">
                                  <h5>
                                    <vue-feather type="image" class="add-info"></vue-feather><span>Images</span>
                                  </h5>
                                  <a href="javascript:void(0);"><vue-feather type="chevron-down"
                                      class="chevron-down-add"></vue-feather></a>
                                </div>
                              </div>
                            </div>
                            <div id="collapseThree" class="accordion-collapse collapse show"
                              aria-labelledby="headingThree" data-bs-parent="#accordionExample3">
                              <div class="accordion-body">
                                <div class="text-editor add-list add">
                                  <div class="col-lg-12">
                                    <div class="add-choosen">
                                      <div class="input-blocks">
                                        <div class="image-upload">
                                          <input type="file" />
                                          <div class="image-uploads">
                                            <i data-feather="plus-circle" class="plus-down-add me-0"></i>
                                            <h4>Add Images</h4>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="phone-img" v-if="isVisible">
                                        <img src="@/assets/img/products/phone-add-2.png" alt="image" />
                                        <a href="javascript:void(0);" @click="hideProduct"><vue-feather type="x"
                                            class="x-square-add remove-product"></vue-feather></a>
                                      </div>
                                      <div class="phone-img" v-if="isVisible1">
                                        <img src="@/assets/img/products/phone-add-1.png" alt="image" />
                                        <a href="javascript:void(0);" @click="hideProduct1"><vue-feather type="x"
                                            class="x-square-add remove-product"></vue-feather></a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                        <div class="row select-color-add">
                          <div class="col-lg-6 col-sm-6 col-12">
                            <div class="input-blocks add-product">
                              <label>Variant Attribute</label>
                              <div class="row">
                                <div class="col-lg-10 col-sm-10 col-10">
                                  <vue-select :options="VariantChoose" id="varianchoose" placeholder="Choose" />
                                </div>
                                <div class="col-lg-2 col-sm-2 col-2 ps-0">
                                  <div class="add-icon tab">
                                    <a class="btn btn-filter" data-bs-toggle="modal" data-bs-target="#add-units"><i
                                        class="feather feather-plus-circle"></i></a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="selected-hide-color" id="input-show">
                              <div class="row align-items-center">
                                <div class="col-sm-10">
                                  <div class="input-blocks">
                                    <vue3-tags-input class="input-tags form-control" type="text" data-role="tagsinput"
                                      name="inputBox" id="inputBox" :tags="tags" />
                                  </div>
                                </div>
                                <div class="col-lg-2">
                                  <div class="input-blocks">
                                    <a href="javascript:void(0);" class="remove-color"><i
                                        class="far fa-trash-alt"></i></a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="modal-body-table variant-table" id="variant-table">
                          <div class="table-responsive">
                            <table class="table">
                              <thead>
                                <tr>
                                  <th>Variantion</th>
                                  <th>Variant Value</th>
                                  <th>SKU</th>
                                  <th>Quantity</th>
                                  <th>Price</th>
                                  <th class="no-sort">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <div class="add-product">
                                      <input type="text" class="form-control" value="color" />
                                    </div>
                                  </td>
                                  <td>
                                    <div class="add-product">
                                      <input type="text" class="form-control" value="red" />
                                    </div>
                                  </td>
                                  <td>
                                    <div class="add-product">
                                      <input type="text" class="form-control" value="1234" />
                                    </div>
                                  </td>
                                  <td>
                                    <div class="product-quantity">
                                      <span class="quantity-btn"><i data-feather="minus-circle"
                                          class="feather-search"></i></span>
                                      <input type="text" class="quntity-input" value="2" />
                                      <span class="quantity-btn">+<i data-feather="plus-circle"
                                          class="plus-circle"></i></span>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="add-product">
                                      <input type="text" class="form-control" value="50000" />
                                    </div>
                                  </td>
                                  <td class="action-table-data">
                                    <div class="edit-delete-action">
                                      <div class="input-block add-lists">
                                        <label class="checkboxs">
                                          <input type="checkbox" checked />
                                          <span class="checkmarks"></span>
                                        </label>
                                      </div>
                                      <a class="me-2 p-2" href="javascript:void(0);" data-bs-toggle="modal"
                                        data-bs-target="#add-variation">
                                        <i data-feather="plus" class="feather-edit"></i>
                                      </a>
                                      <a class="confirm-text p-2" @click="showConfirmation" href="javascript:void(0);">
                                        <i data-feather="trash-2" class="feather-trash-2"></i>
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div class="add-product">
                                      <input type="text" class="form-control" value="color" />
                                    </div>
                                  </td>
                                  <td>
                                    <div class="add-product">
                                      <input type="text" class="form-control" value="black" />
                                    </div>
                                  </td>
                                  <td>
                                    <div class="add-product">
                                      <input type="text" class="form-control" value="2345" />
                                    </div>
                                  </td>
                                  <td>
                                    <div class="product-quantity">
                                      <span class="quantity-btn"><i data-feather="minus-circle"
                                          class="feather-search"></i></span>
                                      <input type="text" class="quntity-input" value="3" />
                                      <span class="quantity-btn">+<i data-feather="plus-circle"
                                          class="plus-circle"></i></span>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="add-product">
                                      <input type="text" class="form-control" value="50000" />
                                    </div>
                                  </td>
                                  <td class="action-table-data">
                                    <div class="edit-delete-action">
                                      <div class="input-block add-lists">
                                        <label class="checkboxs">
                                          <input type="checkbox" checked />
                                          <span class="checkmarks"></span>
                                        </label>
                                      </div>
                                      <a class="me-2 p-2" href="javascript:void(0);" data-bs-toggle="modal"
                                        data-bs-target="#edit-units">
                                        <i data-feather="plus" class="feather-edit"></i>
                                      </a>
                                      <a class="confirm-text p-2" @click="showConfirmation" href="javascript:void(0);">
                                        <i data-feather="trash-2" class="feather-trash-2"></i>
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-card-one accordion" id="accordionExample4">
              <div class="accordion-item">
                <div class="accordion-header" id="headingFour">
                  <div class="accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseFour"
                    aria-controls="collapseFour">
                    <div class="text-editor add-list">
                      <div class="addproduct-icon list">
                        <h5>
                          <vue-feather type="list" class="add-info"></vue-feather><span>Custom Fields</span>
                        </h5>
                        <a href="javascript:void(0);"><vue-feather type="chevron-down"
                            class="chevron-down-add"></vue-feather></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="collapseFour" class="accordion-collapse collapse show" aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample4">
                  <div class="accordion-body">
                    <div class="text-editor add-list add">
                      <div class="custom-filed">
                        <div class="input-block add-lists">
                          <label class="checkboxs">
                            <input type="checkbox" />
                            <span class="checkmarks"></span>Warranties
                          </label>
                          <label class="checkboxs">
                            <input type="checkbox" />
                            <span class="checkmarks"></span>Manufacturer
                          </label>
                          <label class="checkboxs">
                            <input type="checkbox" />
                            <span class="checkmarks"></span>Expiry
                          </label>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-4 col-sm-6 col-12">
                          <div class="input-blocks add-product">
                            <label>Discount Type</label>
                            <vue-select :options="Distype" id="distype" placeholder="Choose" />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-4 col-sm-6 col-12">
                          <div class="input-blocks add-product">
                            <label>Quantity Alert</label>
                            <input type="text" class="form-control" />
                          </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-12">
                          <div class="input-blocks">
                            <label>Manufactured Date</label>

                            <div class="input-groupicon calender-input">
                              <vue-feather type="calendar" class="info-img"></vue-feather>
                              <date-picker v-model="startdate" placeholder="Choose Date" class="form-control"
                                :editable="true" :clearable="false" :input-format="dateFormat" />
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-12">
                          <div class="input-blocks">
                            <label>Expiry On</label>

                            <div class="input-groupicon calender-input">
                              <vue-feather type="calendar" class="info-img"></vue-feather>
                              <date-picker v-model="startdateOne" placeholder="Choose Date" class="form-control"
                                :editable="true" :clearable="false" :input-format="dateFormat" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="btn-addproduct mb-4">
            <button type="button" class="btn btn-cancel me-2">Cancel</button>
            <button type="submit" class="btn btn-submit">Save Product</button>
          </div>
        </div>
      </form>
      <!-- /add -->
    </div>
  </div>
  <add-product-modal></add-product-modal>
</template>
<script>
import { ref } from "vue";
const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
import Vue3TagsInput from "vue3-tags-input";
import Swal from "sweetalert2";
import {brandsService} from "@/services/brands.service";
import {categoryService} from "@/services/categories.service";
import {unitService} from "@/services/units.services";
import {productService} from "@/services/products.service";

var brands = [];
var units = [];
var categories = [];

export default {
  components: {
    Vue3TagsInput,
  },
  emits: ["refetch-products"],
  data() {
    return {
      isVisible: true,
      isVisible1: true,
      Distype: ["Choose", "Percentage", "Cash"],
      VariantChoose: ["Choose", "Color", "Red", "Black"],
      ChooseTypeDis: [{ id: "PERCENTAGE", text: "Percentage" }, { id: "CASH", text: "Cash" }],
      ExclusiveType: ["Exclusive", "Sales Tax"],
      ChooseCode: ["Choose", "Code34", "Code35", "Code36"],
      SellingType: ["Choose", "Transactional selling", "Solution selling"],
      SubSubCategory: ["Choose", "Fruits", "Computers", "Shoes"],
      SubCategory: ["Choose", "Lenovo", "Electronics"],
      WarhouseStore: ["Choose", "Legendary", "Determined", "Sincere"],
      ThomasStore: ["Choose", "Thomas", "Rasmussen", "Fred john"],
      tags: ["red", "black"],
      startdate: currentDate,
      startdateOne: currentDateOne,
      dateFormat: "dd-MM-yyyy",
      productName: '',
      productSlug: '',
      description: '',
      productType: 'SINGLE_PRODUCT',
      quantity: 0,
      price: 0,
      discountType: '',
      discount: 0,
      quantityAlert: 0,
      brand: null,
      unit: null,
      category: null,
      brands: [],
      units: [],
      categories: [],
    };
  },
  methods: {
    hideProduct() {
      this.isVisible = false;
    },
    hideProduct1() {
      this.isVisible1 = false;
    },
    showConfirmation() {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          Swal.fire({
            icon: "success",
            title: "Deleted!",
            text: "Your file has been deleted.",
            confirmButtonClass: "btn btn-success",
          });
        }
      });
    },
    fetchBrands() {
       brandsService.getAllBrands(0,20).then(data => {
          this.brands = data.content?.reduce((acc, brand) => {
              acc.push({ id: brand.brandId, text: brand.brandName });
              return acc;
            }, []);
            brands = data.content;
       }
       ).catch(error => console.error('Error:', error));
    },
    fetchCategories() {
       categoryService.getAllCategory(0,20).then(data => {
          this.categories = data.content?.reduce((acc, category) => {
              acc.push({ id: category.categoryId, text: category.categoryName });
              return acc;
            }, []);
            categories = data.content;
       }
       ).catch(error => console.error('Error:', error));
    },
    fetchUnits() {
       unitService.getAllUnits(0,20).then(data => {
         this.units = data.content?.reduce((acc, unit) => {
              acc.push({ id: unit.unitId, text: `${unit.unitName} (${unit.unitSymbol})` });
              return acc;
            }, []);
            units = data.content;
           }
       ).catch(error => console.error('Error:', error));
    },
    submitForm() {
      const body = {
            "productId": 0,
            "productName": this.productName,
            "image": "stock-img-02.png",
            "description": this.description,
            "discountType": this.discountType,
            "productType": this.productType,
            "quantity": this.quantity,
            "quantityAlert": this.quantityAlert,
            "price": this.price,
            "discount": this.discount,
            "specialPrice": 0,
            "category": categories.filter((category) => {
              return category.categoryId == this.category
            })[0],
            "brand": brands.filter((brand) => {
              return brand.brandId == this.brand
            })[0],
            "tax": null,
            "unit": units.filter((unit) => {
              return unit.unitId == this.unit
            })[0]
          }

       productService.addProduct(body, this.category)
           .then(() => {
             this.$router.push("/inventory/product-list");
             this.$emit('refetch-products');
          }).catch(error => console.error('Error:', error));
    },
  },
  toggleCollapse() {
    const collapseHeader = this.$refs.collapseHeader;

    if (collapseHeader) {
      collapseHeader.classList.toggle("active");
      document.body.classList.toggle("header-collapse");
    }
  },
  created() {
    this.fetchBrands();
    this.fetchCategories();
    this.fetchUnits();
  }
};
</script>
