import moment from 'moment';

export function formattedDate(date) {
      const dateOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
      const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: false }; // Use 24-hour format

      const datePart = date?.toLocaleDateString('en-CA', dateOptions).replace(/\//g, '-'); // Use Canadian locale for YYYY-MM-DD format, then replace slashes with hyphens
      const timePart = date?.toLocaleTimeString('en-CA', timeOptions).replace(/\//g, ':');

      return `${datePart}-${timePart}`;
}

export function  format_date(value) {
      if (value) {
        return moment(String(value)).format('MMMM DD, YYYY')
      }
}
