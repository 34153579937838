<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <div class="page-header">
        <div class="page-title">
          <h4>Product Details</h4>
          <h6>Full details of a product</h6>
        </div>
      </div>
      <!-- /add -->
      <div class="row" v-if="product">
        <div class="col-lg-8 col-sm-12">
          <div class="card">
            <div class="card-body">
              <div class="bar-code-view">
                <img src="@/assets/img/barcode/barcode1.png" alt="barcode" />
                <a class="printimg">
                  <img src="@/assets/img/icons/printer.svg" alt="print" />
                </a>
              </div>
              <div class="productdetails">
                <ul class="product-bar">
                  <li>
                    <h4>Product</h4>
                    <h6>{{ product.productName }}</h6>
                  </li>
                  <li>
                    <h4>Category</h4>
                    <h6>{{ product.category.categoryName }}</h6>
                  </li>
                  <li>
                    <h4>Brand</h4>
                    <h6>{{ product.brand.brandName }}</h6>
                  </li>
                  <li>
                    <h4>Unit</h4>
                    <h6>{{ product.unit.unitName }}</h6>
                  </li>
                  <li>
                    <h4>SKU</h4>
                    <h6>
                      <p>{{ $route.query.sku }}</p>
                    </h6>
                  </li>
                  <li>
                    <h4>Selling type</h4>
                    <h6>
                      <p>{{ product.sellingType == "RETAILS" ? "Retail" : "Wholesale" }}</p>
                    </h6>
                  </li>
                  <li>
                    <h4>Minimum Qty</h4>
                    <h6>{{ product.quantityAlert }}</h6>
                  </li>
                  <li>
                    <h4>Quantity</h4>
                    <h6>{{ product.quantity }}</h6>
                  </li>
                  <li v-if="product.tax">
                    <h4>Tax</h4>
                    <h6>0.00 %</h6>
                  </li>
                  <li v-if="product.discount > 0">
                    <h4>Discount {{ product.discountType == "CASH" ? "($)" : "(%)" }}</h4>
                    <h6> {{ product.discount }}</h6>
                  </li>
                  <li>
                    <h4>Price</h4>
                    <h6>{{ product.specialPrice }}</h6>
                  </li>
                  <li>
                    <h4>Status</h4>
                    <h6>
                      <span
                        :class="`badge ${product.quantity > product.quantityAlert ? 'badge-success' : product.quantityAlert > 0 ? 'badge-warning' : 'badge-danger'}`">
                        {{ product.quantity > product.quantityAlert ? "In stock" : product.quantityAlert > 0 ? "Low stock" : "Out of stock" }}
                      </span>
                    </h6>
                  </li>
                  <li>
                    <h4>Description</h4>
                    <h6>
                      {{ product.description }}
                    </h6>
                  </li>
                  <li>
                    <h4>Last updated</h4>
                    <h6>{{ product.lastUpdatedAt }}</h6>
                  </li>
                  <li v-if="product.manufacturedDate">
                    <h4>Manufactured Date</h4>
                    <h6>{{ product.manufacturedDate }}</h6>
                  </li>
                  <li v-if="product.expiryOn">
                    <h4>Expiry Date</h4>
                    <h6>{{ product.expiryOn }}</h6>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-sm-12">
          <div class="card">
            <div class="card-body">
              <div class="slider-product-details">
                <div class="owl-carousel owl-theme product-slide">
                  <div class="slider-product">
                    <img :src="require(`@/assets/img/products/${product.image}`)" :alt="product.image" />
                    <!-- <h4>macbookpro.jpg</h4>
                    <h6>581kb</h6> -->
                  </div>
                  <div class="slider-product">
                    <img src="@/assets/img/products/product69.jpg" alt="img" />
                    <!-- <h4>macbookpro.jpg</h4>
                    <h6>581kb</h6> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- /add -->
    </div>
  </div>
</template>

<script>
import {productService} from "@/services/products.service";

export default {
  data() {
    return {
      product: null
    };
  },
  methods: {
    fetchData() {
       productService.getProductById(this.$route.query.sku).then(data => this.product = data
       ).catch(error => console.error('Error:', error));
    },
  },
  created() {
    this.fetchData();
  }
};
</script>
