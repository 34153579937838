import {handleResponse} from "@/utils/handle-response";
import {requestOptions} from "@/utils/request-options";


export const productService = {
    getAllproducts,
    getProductById,
    getLowStocks,
    addProduct,
    printInvoice,
    getProductsByKeywords
};

function getAllproducts(pageNumber, pageSize) {
    return fetch(`${process.env.VUE_APP_BASE_URL}/api/public/products?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=productName&sortOrder=asc`, requestOptions.get())
        .then(handleResponse)
}

function getProductById(sku) {
   return fetch(`${process.env.VUE_APP_BASE_URL}/api/public/products/sku/${sku}`, requestOptions.get())
        .then(handleResponse)
}

function getLowStocks(pageNumber, pageSize) {
    return fetch(`${process.env.VUE_APP_BASE_URL}/api/admin/products/low-stock?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=productName&sortOrder=asc`, requestOptions.get())
        .then(handleResponse)
}

function getProductsByKeywords(keyword, pageNumber, pageSize) {
    return fetch(`${process.env.VUE_APP_BASE_URL}/api/public/products/keyword/${keyword}?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=productName&sortOrder=asc`, requestOptions.get())
        .then(handleResponse)
}

function addProduct(body, category) {
    return fetch(`${process.env.VUE_APP_BASE_URL}/api/admin/categories/${category}/product`, requestOptions.post(body))
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function printInvoice(body) {
    return fetch(`${process.env.VUE_APP_BASE_URL}/api/invoices/generate`, requestOptions.post(body))
        .then(response => response.blob())
        .then(blob => {
          return blob;
        });
}
