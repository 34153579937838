<template>
  <div>
    <button @click="generateInvoice">Generate Invoice</button>
    <div v-if="pdfPreviewUrl" class="pdf-preview-container">
      <button @click="closePreview">Close Preview</button>
      <iframe :src="pdfPreviewUrl" class="pdf-preview"></iframe>
    </div>
  </div>
</template>

<script>
import 'jspdf-autotable';
import {productService} from "@/services/products.service";

export default {
  data() {
    return {
      invoiceData: {
        invoiceNumber: 'INV-2024-1001',
        invoiceDate: '2024-10-26',
        dueDate: '2024-11-26',
        clientName: 'Acme Corporation',
        clientAddress: '123 Main St, Anytown',
        items: [
          { description: 'Consulting Services', quantity: 2, unitPrice: 500 },
          { description: 'Web Development', quantity: 1, unitPrice: 2000 },
          { description: 'Project Management', quantity: 3, unitPrice: 250 },
        ],
        taxRate: 0.10,
        couponCode: 'SAVE10',
        discountPercentage: 0.05,
        notes: 'Thank you for your business!',
      },
      pdfPreviewUrl: null,
    };
  },
  computed: {
    subTotal() {
      return this.invoiceData.items.reduce((sum, item) => sum + item.quantity * item.unitPrice, 0);
    },
    taxAmount() {
      return this.subTotal * this.invoiceData.taxRate;
    },
    discountAmount() {
      return this.invoiceData.couponCode === 'SAVE10' ? this.subTotal * this.invoiceData.discountPercentage : 0;
    },
    totalAmount() {
      return this.subTotal + this.taxAmount - this.discountAmount;
    },
  },
  methods: {
    generateInvoice() {
      productService.printInvoice(this.invoiceData)
           .then(blob => {
             const url = window.URL.createObjectURL(blob);
             this.pdfPreviewUrl = url;
            //Print logic
            // setTimeout(() => {
            //   const iframe = document.querySelector('.pdf-preview');
            //   if (iframe) {
            //     iframe.contentWindow.print();
            //   }
            //   this.closePreview();
            // }, 500);
          }).catch(error => console.error('Error generating PDF:', error));
    },
    closePreview() {
      this.pdfPreviewUrl = null;
    },
  },
};
</script>

<style scoped>
.pdf-preview-container {
  position: relative;
  width: 100%;
  overflow: auto;
}

.pdf-preview {
  width: 100%;
  height: 80vh;
  border: 1px solid #ccc;
  transform: scale(0.7);
  transform-origin: top left;
}

.pdf-preview-container button {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
}
</style>
