<template>
  <!-- Add Category -->
  <div class="modal fade" id="add-category" ref="vuemodal">
    <div class="modal-dialog modal-dialog-centered custom-modal-two">
      <div class="modal-content">
        <div class="page-wrapper-new p-0">
          <div class="content">
            <div class="modal-header border-0 custom-modal-header">
              <div class="page-title">
                <h4>Create Category</h4>
              </div>
              <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body custom-modal-body">
              <form @submit.prevent="submitForm">
                <div class="mb-3">
                  <label class="form-label" for="categoryName">Category</label>
                  <input type="text" v-model="categoryName" class="form-control" />
                </div>
                <div class="mb-3">
                  <label class="form-label" for="categorySlug">Category Slug</label>
                  <input type="text" class="form-control"  v-model="categorySlug"/>
                </div>
                <div class="mb-0">
                  <div class="status-toggle modal-status d-flex justify-content-between align-items-center">
                    <span class="status-label">Status</span>
                    <input type="checkbox" id="user2" class="check" v-model="categoryStatus" checked="" />
                    <label for="user2" class="checktoggle"></label>
                  </div>
                </div>
                <div class="modal-footer-btn">
                  <button type="button" class="btn btn-cancel me-2" data-bs-dismiss="modal">
                    Cancel
                  </button>
                  <button type="submit" class="btn btn-submit" data-bs-dismiss="modal">Create Category</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Category -->

  <!-- Edit Category -->
  <div class="modal fade" id="edit-category">
    <div class="modal-dialog modal-dialog-centered custom-modal-two">
      <div class="modal-content">
        <div class="page-wrapper-new p-0">
          <div class="content">
            <div class="modal-header border-0 custom-modal-header">
              <div class="page-title">
                <h4>Edit Category</h4>
              </div>
              <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body custom-modal-body">
              <form @submit.prevent="submitForm">
                <div class="mb-3">
                  <label class="form-label">Category</label>
                  <input type="text" v-model="categoryName" class="form-control" value="Laptop" />
                </div>
                <div class="mb-3">
                  <label class="form-label">Category Slug</label>
                  <input type="text" class="form-control" value="laptop" />
                </div>
                <div class="mb-0">
                  <div class="status-toggle modal-status d-flex justify-content-between align-items-center">
                    <span class="status-label">Status</span>
                    <input type="checkbox" id="user3" class="check" checked="" />
                    <label for="user3" class="checktoggle"></label>
                  </div>
                </div>
                <div class="modal-footer-btn">
                  <button type="button" class="btn btn-cancel me-2" data-bs-dismiss="modal">
                    Cancel
                  </button>
                  <button type="submit" class="btn btn-submit">Save Changes</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Edit Category -->
</template>

<script>
import {categoryService} from "@/services/categories.service";

export default {

  emits: ["refetch-categories"],
  data() {
    return {
      categoryName: '',
      categorySlug: '',
      categoryStatus: true,
    };
  },
  methods: {
    submitForm() {
      categoryService.addCategory(this.categoryName, this.categorySlug, this.status ? "ACTIVE" : "DEACTIVATED")
           .then(() => {
             this.$router.push("/inventory/category-list");
          this.$emit('refetch-categories');
          }).catch(error => console.error('Error:', error));
    },
  },
}
</script>
