<template>
  <div class="sticky-bottom-bar">
    <div class="container-fluid">
      <div class="row align-items-center justify-content-between">
        <div class="col-auto">
          <div class="d-grid btn-block">
            <a class="btn btn-secondary me-4 ms-4">
              Order Total : ${{ total.toFixed(2) }}
            </a>
          </div>
        </div>
        <div class="col-auto">
          <div class="button-group">
            <button class="btn btn-info me-2" @click="holdTransaction" data-bs-target="#hold-order" data-bs-toggle="modal">
              <span class="me-1 align-items-center">
                <vue-feather class="feather-14 pe-4" type="pause"></vue-feather>
              </span> Hold
            </button>

            <button class="btn btn-danger me-2" @click="cancelTransaction">
              <span class="me-1 align-items-center">
                <vue-feather class="feather-14 pe-4" type="trash-2"></vue-feather>
              </span> Cancel
            </button>

            <button class="btn btn-success me-2" @click="processPayment">
<!--              data-bs-target="#payment-completed" data-bs-toggle="modal"-->
              <span class="me-1 align-items-center">
                <vue-feather class="feather-14 pe-4 " type="credit-card"></vue-feather>
              </span>
              Process Payment
            </button>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {defineEmits, defineProps} from 'vue';

const emit = defineEmits(['hold', 'cancel', 'pay']);
const props = defineProps({
  total: {
    type: Number,
    required: true
  }
})

const holdTransaction = () => {
  emit('hold');
};

const cancelTransaction = () => {
  emit('cancel');
};

const processPayment = () => {
  emit('pay');
};
</script>

<style scoped>
.sticky-bottom-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f8f9fa;
  padding: 10px 0;
  border-top: 1px solid #dee2e6;
  z-index: 1030;
}

.button-group {
  display: flex; /* Use flexbox for spacing */
  align-items: center;
}

/* Optional: Adjust spacing between buttons on larger screens */
@media (min-width: 768px) {
  .button-group .btn {
    margin: 0 5px;
  }

  .button-group .btn:first-child {
    margin-left: 0;
  }

  .button-group .btn:last-child {
    margin-right: 0;
  }
}
</style>
