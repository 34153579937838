import {handleResponse} from "@/utils/handle-response";
import {requestOptions} from "@/utils/request-options";


export const brandsService = {
    getAllBrands,
    getProductById,
    addNewBrand
};

function getAllBrands(pageNumber, pageSize) {
    return fetch(`${process.env.VUE_APP_BASE_URL}/api/admin/brands??pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=brandName&sortOrder=asc`, requestOptions.get())
        .then(handleResponse)
}

function getProductById(sku) {
   return fetch(`${process.env.VUE_APP_BASE_URL}/api/public/products/sku/${sku}`, requestOptions.get())
        .then(handleResponse)
}

function addNewBrand(brandName, brandCode, logo, status) {
    return fetch(`${process.env.VUE_APP_BASE_URL}/api/admin/brand`, requestOptions.post(
      {
            "brandId": 0,
            "brandName": brandName,
            "brandCode": brandCode,
            "logo": logo,
            "status": status,
          }
    ))
        .then(handleResponse)
        .then(data => {
            return data;
        });
}
