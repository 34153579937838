<template>
  <div id="app">
    <div class="main-wrapper">
      <router-view />
      <br/>
      <br/>
      <br/>
      <br/>
    </div>
  </div>
</template>

<script setup lang="ts">
</script>
