<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <div class="page-header">
        <div class="add-item d-flex">
          <div class="page-title">
            <h4>Category</h4>
            <h6>Manage your categories</h6>
          </div>
        </div>
        <ul class="table-top-head">
          <li>
            <a @click="exportToPDF" data-bs-toggle="tooltip" data-bs-placement="top" title="Pdf"><img src="@/assets/img/icons/pdf.svg"
                alt="img" /></a>
          </li>
          <li>
            <a data-bs-toggle="tooltip" data-bs-placement="top" title="Excel"><img src="@/assets/img/icons/excel.svg"
                alt="img" /></a>
          </li>
          <li>
            <a data-bs-toggle="tooltip" data-bs-placement="top" title="Print"><i data-feather="printer"
                class="feather-printer"></i></a>
          </li>
          <li>
            <a data-bs-toggle="tooltip" data-bs-placement="top" title="Refresh"><i data-feather="rotate-ccw"
                class="feather-rotate-ccw"></i></a>
          </li>
          <li>
            <a ref="collapseHeader" data-bs-toggle="tooltip" data-bs-placement="top" title="Collapse"
              @click="toggleCollapse">
              <i data-feather="chevron-up" class="feather-chevron-up"></i>
            </a>
          </li>
        </ul>
        <div class="page-btn">
          <button class="btn btn-added" data-bs-toggle="modal" data-bs-target="#add-category"><vue-feather
              type="plus-circle" class="me-2"></vue-feather>Add New
            Category</button>
        </div>
      </div>
      <!-- /product list -->
      <div v-if="pdfPreviewUrl">
        <iframe :src="pdfPreviewUrl" width="100%" height="600px"></iframe>
       </div>

      <div v-else class="card table-list-card">
        <div class="card-body">
          <div class="table-top">
            <div class="search-set">
              <div class="search-input">
                <input type="text" placeholder="Search" class="dark-input" />
                <a href="" class="btn btn-searchset"><i data-feather="search" class="feather-search"></i></a>
              </div>
            </div>
            <div class="search-path">
              <a class="btn btn-filter" id="filter_search" v-on:click="filter = !filter" :class="{ setclose: filter }">
                <vue-feather type="filter" class="filter-icon"></vue-feather>
                <span><img src="@/assets/img/icons/closes.svg" alt="img" /></span>
              </a>
            </div>
            <div class="form-sort">
              <vue-feather type="sliders" class="info-img"></vue-feather>
              <vue-select :options="CategoryListSort" id="categoryListsort" placeholder="Sort by Date" />
            </div>
          </div>
          <!-- /Filter -->
          <div class="card" :style="{ display: filter ? 'block' : 'none' }" id="filter_inputs">
            <div class="card-body pb-0">
              <div class="row">
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="input-blocks">
                    <vue-feather type="zap" class="info-img"></vue-feather>
                    <vue-select :options="CategorySelect" id="categoryselect" placeholder="Choose Category" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="input-blocks">
                    <vue-feather type="calendar" class="info-img"></vue-feather>
                    <div class="input-groupicon">
                      <date-picker v-model="startdate" placeholder="Choose Date" class="form-control" :editable="true"
                        :clearable="false" :input-format="dateFormat" />
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="input-blocks">
                    <vue-feather type="stop-circle" class="info-img"></vue-feather>
                    <vue-select :options="CategoryStatus" id="categorystatus" placeholder="Choose Status" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12 ms-auto">
                  <div class="input-blocks">
                    <a class="btn btn-filters ms-auto">
                      <i data-feather="search" class="feather-search"></i> Search
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Filter -->
          <div class="table-responsive">
            <a-table class="table datanew" :columns="columns" :data-source="categories" :row-selection="{}">
              <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'categoryStatus'">
                  <div>
                    <span :class="`badge ${record.categoryStatus == 'ACTIVE' ? 'badge-success' : 'badge-danger'}`">{{
                      record.categoryStatus }}</span>
                  </div>
                </template>
                <template v-if="column.key === 'createdDate'">
                  <div>
                    <span>{{ format_date(record.createdDate) }}</span>
                  </div>
                </template>
                <template v-else-if="column.key === 'action'">
                  <td class="action-table-data">
                    <div class="edit-delete-action">
                      <a class="me-2 p-2" href="javascript:void(0);" data-bs-toggle="modal"
                        data-bs-target="#edit-category">
                        <i data-feather="edit" class="feather-edit"></i>
                      </a>
                      <a class="confirm-text p-2" @click="showConfirmation(record.categoryId)"
                        href="javascript:void(0);">
                        <i data-feather="trash-2" class="feather-trash-2"></i>
                      </a>
                    </div>
                  </td>
                </template>
              </template>
            </a-table>
          </div>
        </div>
      </div>
      <!-- /product list -->
    </div>
  </div>
  <category-list-modal @refetch-categories="reFrectchCtegories" />
</template>
<script>
import { ref } from "vue";
import Swal from "sweetalert2";
import moment from 'moment';
import {categoryService} from "@/services/categories.service";
import {fileExportService} from "@/services/file-export.service";

const columns = [
  {
    title: "Category",
    dataIndex: "categoryName",
    sorter: {
      compare: (a, b) => {
        a = a.categoryName.toLowerCase();
        b = b.categoryName.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Category slug",
    dataIndex: "categorySlug",
    sorter: {
      compare: (a, b) => {
        a = a.categorySlug.toLowerCase();
        b = b.categorySlug.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Created On",
    dataIndex: "createdDate",
    key: 'createdDate',
    sorter: {
      compare: (a, b) => {
        a = a.createdDate.toLowerCase();
        b = b.createdDate.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    dataIndex: "categoryStatus",
    key: "categoryStatus",
    sorter: {
      compare: (a, b) => {
        a = a.categoryStatus.toLowerCase();
        b = b.categoryStatus.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "action",
    sorter: true,
  },
];

const currentDate = ref(new Date());
export default {
  data() {
    return {
      filter: false,
      startdate: currentDate,
      dateFormat: "dd-MM-yyyy",
      CategoryListSort: ["Sort by Date", "Newest", "Oldest"],
      CategorySelect: ["Choose Category", "Laptop", "Electronics", "Shoe"],
      CategoryStatus: ["Choose Status", "Active", "Inactive"],
      categories: [],
      columns,
      pdfPreviewUrl: null
    };
  },
  methods: {
    showConfirmation(categoryId) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteCategory(categoryId)
        }
      });
    },
    toggleCollapse() {
      const collapseHeader = this.$refs.collapseHeader;

      if (collapseHeader) {
        collapseHeader.classList.toggle("active");
        document.body.classList.toggle("header-collapse");
      }
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format('MMMM DD, YYYY')
      }
    },
    fetchCategories() {
       categoryService.getAllCategory(0,10).then(categories => this.categories = categories.content
       ).catch(error => console.error('Error:', error));
    },
    exportToPDF() {
      if(this.pdfPreviewUrl) {
        this.pdfPreviewUrl = null;
      } else {
        const headers = ['categoryName', 'categorySlug', 'categoryStatus',  'createdDate'];
       const columns = [
          { content:'Category Name', styles: { halign: 'left' } }, // Left-aligned
          { content: 'Slug', styles: { halign: 'center' } }, // Center-aligned
          { content: 'Status', styles: { halign: 'center' } }, //
          { content: 'Date of creation', styles: { halign: 'center'} }
      ];
       const columnStyles = { // Style specific columns
        1: { halign: 'center' }, // Center the 3rd column (index 2)
        2: { halign: 'center' },
        3: { halign: 'center' }};

       const title = "Product Categories Report";
       this.pdfPreviewUrl = fileExportService.pdfExport(columns, headers, this.categories, "product-category", columnStyles, title);
      }
    },
    deleteCategory(categoryId) {
      fetch(`${process.env.VUE_APP_BASE_URL}/api/admin/categories/${categoryId}`, {
        method: "DELETE",
        headers: {
          "Authorization": `Bearer ${process.env.VUE_APP_TOKEN}`,
        },
      })
        .then((data) => {
          if (data.status == 202) {
            this.categories = this.categories.filter(function (obj) {
              return obj.categoryId !== categoryId;
            });
            this.$forceUpdate();
            Swal.fire({
              icon: "success",
              title: "Deleted!",
              text: "Category with categoryId: " + categoryId + " deleted successfully !!!",
              confirmButtonClass: "btn btn-success",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Operation failed!",
              text: "Not accepted, there are products on the stock !!!",
              confirmButtonClass: "btn btn-success",
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    reFrectchCtegories(){
        this.fetchCategories();
        this.$forceUpdate();
    }
  },
  async created() {
    this.fetchCategories();
  }
};
</script>
